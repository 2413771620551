export const structurizeDownloadedData = (products) => {
    const filteredData: any[] = [];
  
    products?.forEach((product) => {
      const structuredData = {};
      for (let [key, val] of Object.entries(product)) {
        structuredData["slug"] = product?.slug; // to hightlight in first column
        // structuredData["campaignId"] = campaignId;
        if (
          key !== "id" &&
          key !== "slug" &&
          key !== "deleted" &&
          key !== "isActive" &&
          key !== "createdAt" &&
          key !== "variations" &&
          key !== "shop" &&
          key !== "hubId" &&
          !Array.isArray(val) &&
          typeof val !== "object"
        ) {
          structuredData[key] = val;
        } else if (key === "variations") {
          for (let i = 0; i < product?.["variations"]?.length; i++) {
            structuredData[`variations-barCode${i ? i : ``}`] =
              product?.["variations"]?.[i]?.barCode;
            structuredData[`variations-imageUrl${i ? i : ``}`] =
              product?.["variations"]?.[i]?.imageUrl;
            structuredData[`variations-maximumOrderLimit${i ? i : ``}`] =
              product?.["variations"]?.[i]?.maximumOrderLimit;
            structuredData[`variations-regularVariationId${i ? i : ``}`] =
              product?.["variations"]?.[i]?.regularVariationId;
            structuredData[`variations-stock${i ? i : ``}`] =
              product?.["variations"]?.[i]?.stock;
            structuredData[`variations-variationId${i ? i : ``}`] =
              product?.["variations"]?.[i]?.variationId;
            structuredData[`variations-variationName${i ? i : ``}`] =
              product?.["variations"]?.[i]?.variationName;
            structuredData[`variations-price:mrp${i ? i : ``}`] =
              product?.["variations"]?.[i]?.price?.mrp;
            structuredData[`variations-price:tp${i ? i : ``}`] =
              product?.["variations"]?.[i]?.price?.tp;
            structuredData[`variations-productDiscount:flat${i ? i : ``}`] =
              product?.["variations"]?.[i]?.productDiscount?.flat;
            structuredData[`variations-productDiscount:percentage${i ? i : ``}`] =
              product?.["variations"]?.[i]?.productDiscount?.percentage;
            structuredData[
              `variations-quantitativeProductDiscount:freeProductId${i ? i : ``}`
            ] =
              product?.["variations"]?.[
                i
              ]?.quantitativeProductDiscount?.freeProductId;
            structuredData[
              `variations-quantitativeProductDiscount:minimumQuantity${
                i ? i : ``
              }`
            ] =
              product?.["variations"]?.[
                i
              ]?.quantitativeProductDiscount?.minimumQuantity;
            structuredData[
              `variations-quantitativeProductDiscount:productDiscount:flat${
                i ? i : ``
              }`
            ] =
              product?.["variations"]?.[
                i
              ]?.quantitativeProductDiscount?.productDiscount?.flat;
            structuredData[
              `variations-quantitativeProductDiscount:productDiscount:percentage${
                i ? i : ``
              }`
            ] =
              product?.["variations"]?.[
                i
              ]?.quantitativeProductDiscount?.productDiscount?.percentage;
          }
        } else if (key === "images") {
          structuredData[`images`] = product?.["images"]?.toString();
        }
      }
      // structuredData["images"] = row["images"]
      //   ?.split(",")
      //   ?.filter((url) => url);
  
      filteredData.push(structuredData);
    });
  
    return filteredData;
  };