import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "../components/common/404";
import LandingPage from "../pages";
import OldSignUpPage from "../pages/oldsignup";
import SignUpPage from "../pages/signup";
import LoginPage from "../pages/Login";
import Membership from "../pages/membership/index"; 
import MemebrshipPaymentCheckoutPage from "../pages/membership/MemebrshipPaymentCheckoutPage";
import BusinessCreatePage from "../pages/merchant-profile/business-create";
import MerchantProfilePage from "../pages/merchant-profile/index";
import PersonalDetailsPage from "../pages/merchant-profile/personal-details";
import BusinessInfoPage from "../pages/merchant-profile/merchant-business-info";
import BusinessContractAddressPage from "../pages/merchant-profile/merchant-business-contract-address";
//import BankInfoPage from "../pages/merchant-profile/bank-info";
import BankInfoPage from "../pages/administration/services";
import { adminServices, isAllowedService } from "../utils/services";
 
const MainRoutes = (): any => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="signup" element={<SignUpPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="oldsignup" element={<OldSignUpPage />} /> 
        <Route path="membership" element={<Membership />} />
        <Route path="membership-payment-checkout" element={<MemebrshipPaymentCheckoutPage />} />
        <Route path="business/create" element={<BusinessCreatePage />} />
        <Route path="business/personal-details" element={<MerchantProfilePage />} />
        <Route path="personal-details" element={<PersonalDetailsPage />} />
        <Route path="business-info" element={<BusinessInfoPage />} />
        <Route path="business-contract-address" element={<BusinessContractAddressPage />} />
        <Route path="bank-info" element={<BankInfoPage />} />
        {!token ? (
          <>
            <Route path="*" element={<LandingPage />} />
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<LandingPage />}
            />
            
          </>
        ) : (
          <>
            <Route path="*" element={<NotFound />} />
            {adminServices?.flatMap((service, index) => {
              if (service?.sub?.length) {
                return [
                  ...(service?.sub?.map((sub, i) => {
                    const ServiceElemnt = sub?.element;
                    return (
                      isAllowedService(`${service.name} ${sub.name}`) &&
                      ServiceElemnt && (
                        <Route
                          key={i}
                          path={`${process.env.PUBLIC_URL}${service?.path}${sub?.path}`}
                          element={<ServiceElemnt />}
                        />
                      )
                    );
                  }) || []),
                ];
              } else {
                const ServiceElemnt = service?.element;
                return (
                  isAllowedService(service?.name) && (
                    <Route
                      key={index}
                      path={`${process.env.PUBLIC_URL}${service?.path}`}
                      element={<ServiceElemnt />}
                    />
                  )
                );
              }
            })}
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
