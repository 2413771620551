import {
  AppstoreOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../utils/services";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { SubMenu } = Menu;

const ShopSidebar = ({ shopId }: any): ReactElement => {
  const pathname = window.location?.pathname;
  const { token, type, shopInfo, merchantInfo, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="inline">
            <SubMenu
              key={"sub1"}
              icon={<AppstoreOutlined />}
              title={"Basic Information"}
            >
              <Menu.Item
                key="/details"
                className={`hover:text-white hover:bg-gray-100 ${
                  pathname.includes("/details") ? "active bg-gray-100" : ""
                }`}
                icon={<IdcardOutlined />}
              >
                <Link
                  to={`/shops/${shopId}/details`}
                  className="hover:text-white"
                >
                  Basic Information
                </Link>
              </Menu.Item>
              <Menu.Item
                key="/banks"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/banks") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/banks`}>Bank Info</Link>
              </Menu.Item>
              <Menu.Item
                key="/users"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/users") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/users`}>Users</Link>
              </Menu.Item>
              <Menu.Item
                key="/contact-address"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/contact-address")
                    ? "active bg-gray-100"
                    : ""
                }
              >
                <Link to={`/shops/${shopId}/contact-address`}>
                  Business Info
                </Link>
              </Menu.Item>
            </SubMenu>

            {type !== "JC_BOOKING" && (
              <>
                <SubMenu
                  key={"sub2"}
                  icon={<AppstoreOutlined />}
                  title={"Product Management"}
                >
                  <Menu.Item
                    key="/products"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/products") ? "active bg-gray-100" : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/products`}>Product</Link>
                  </Menu.Item>
                  {merchantInfo?.type !== "RESELLER" && (
                    <>
                      <Menu.Item
                        key="/requested-products"
                        icon={<SolutionOutlined />}
                        className={
                          pathname.includes("/requested-products")
                            ? "active bg-gray-100"
                            : ""
                        }
                      >
                        <Link to={`/shops/${shopId}/requested-products`}>
                          Requested Products
                        </Link>
                      </Menu.Item>
                    </>
                  )}
                  <Menu.Item
                    key="/product-catalogue"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/product-catalogue")
                        ? "active bg-gray-100"
                        : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/product-catalogue`}>
                      Product Catalogue
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/out-of-stock"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/out-of-stock")
                        ? "active bg-gray-100"
                        : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/out-of-stock`}>
                      Out of Stock
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/categories"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/categories")
                        ? "active bg-gray-100"
                        : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/categories`}>
                      Category Management
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/brands"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/brands") ? "active bg-gray-100" : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/brands`}>Brand List</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key={"sub3"}
                  icon={<AppstoreOutlined />}
                  title={"Promotion"}
                >
                  <Menu.Item
                    key="/promo-code"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/promo-code")
                        ? "active bg-gray-100"
                        : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/promo-code`}>Promo Code</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/campaign"
                    icon={<SolutionOutlined />}
                    className={
                      pathname.includes("/campaign") ? "active bg-gray-100" : ""
                    }
                  >
                    <Link to={`/shops/${shopId}/campaign`}>Campaign</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item
                  key="/orders"
                  icon={<ShoppingCartOutlined />}
                  className={`hover:text-white hover:bg-gray-100 ${
                    pathname.includes("/orders") ? "active bg-gray-100" : ""
                  }`}
                >
                  <Link to={`/shops/${shopId}/orders`}>Orders History</Link>
                </Menu.Item>

                {/* <Menu.Item
                  key="/business-info"
                  icon={<SolutionOutlined />}
                  className={
                    pathname.includes("/business-info")
                      ? "active bg-gray-100"
                      : ""
                  }
                >
                  <Link to={`/shops/${shopId}/business-info`}>
                    Buiness Info
                  </Link>
                </Menu.Item> */}

                <Menu.Item
                  key="/chats"
                  icon={<SolutionOutlined />}
                  className={
                    pathname.includes("/chats") ? "active bg-gray-100" : ""
                  }
                >
                  <Link to={`/shops/${shopId}/chats`}>Chats</Link>
                </Menu.Item>
                {/* <Menu.Item
                  key="/shop-delivery-settings"
                  icon={<SolutionOutlined />}
                  className={
                    pathname.includes("/shop-delivery-settings")
                      ? "active bg-gray-100"
                      : ""
                  }
                >
                  <Link to={`/shops/${shopId}/shop-delivery-settings`}>
                    Shipping & Delivery
                  </Link>
                </Menu.Item> */}
              </>
            )}

            {/* <Menu.Item
              key="/transactions"
              icon={<SolutionOutlined />}
              className={pathname.includes("/transactions") ? "active" : ""}
            >
              <Link to={`/shops/${shopId}/transactions`}>
                Transaction History
              </Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
