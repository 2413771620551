import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { FieldValues } from "react-hook-form";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { login } from "../../redux/auth/authAction";
import ForgotPassword from "./ForgotPassword";
import AllTypes from "../common/Types";
import { responseNotification } from "../../utils/notify";
import axios from "axios";
import { debounce } from "lodash";
import { LOGIN_SUCCESS } from "../../redux/auth/authType";

function Login({ formType, refetch }: { formType: boolean; refetch: any }) {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<any>("BD");
  const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
  const [loading, setLoading] = useState(false);
  const [signup, setSignup] = useState(false);
  const [otherLogin, setOtherLogin] = useState(false);
  const [otherSignup, setOtherSignup] = useState(false);
  const [terms, setTerms] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const userType = "SUPER_MERCHANT";
  const currentURL = location.pathname;
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [visible, setVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+88");
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [refferrCode, setRefferrCode] = useState("");
  const [tramsCondation, setTramsCondation] = useState("");
  const [singleCountry, setSingleCountry] = useState<any>({
    loading: false,
    data: null,
  });
  const [sendOtp, setSendOtp] = useState(true);
  const [count, setCount] = useState(0);
  const [merchantShopInfo, setMerchantShopInfo] = useState<any>();
  const [view, setView] = useState<
    | "login"
    | "emailLogin"
    | "emailSiginup"
    | "siginup"
    | "set"
    | "reset"
    | "success"
    | "otp"
    | "forgot"
    | "forgotted"
  >(currentURL === "/signup" ? "siginup" : "login");

  const onClose = () => {
    setVisible(false);
  };

  const MerchantLogin = async (data: FieldValues) => {
    const mobileNumber = data?.mobileNumber;
    const passwordData = data?.password;
    setLoading(true);
    const response1 = await login(dispatch, {
      mobileNumber: emailName || mobileNumber,
      countryCode: countryId,
      password: passwordData,
      type: type,
      token: token,
    });
    setLoading(false);

    // if (response1) {
    //   navigate("/");
    // }

    if (response1 === "newMerchant") {
      navigate("/merchant-profile");
    } else if (response1 === "alreadyMerchant") {
      navigate("/");
    } else {
      return false;
    }
  };
  const onSubmitOtp = async (data: FieldValues) => {
    const newPassword = data?.confirm;
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_API}/otp/send-register-otp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumberOrEmail: emailName || mobileNumber,
          countryCode: countryId,
          userType: userType,
        }),
      }
    );

    const response1 = await res.json();

    if (response1?.statusCode === 200) {
      setView("otp");
      responseNotification(
        response1?.message?.replace("Operation", "Otp sent successfully!"),
        "success"
      );
      setCount(60);
      startCountdown();
    } else if (response1.statusCode === 500) {
      responseNotification("Internal server error", "error");
    } else {
      responseNotification(response1.message || "something wrong", "warning");
    }
  };

  const onSubmitOtpVerify = async (data: FieldValues) => {
    setLoading(true);
    const newPassword = data?.confirm;
    const otp = data?.otp;
    const res = await fetch(
      `${process.env.REACT_APP_RIDER_API}/merchant/registration/v3`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: data.fullName,
          mobileNumber: emailName || mobileNumber,
          countryCode: countryId,
          password: password,
          isReseller: isReseller,
          referrerCode: refferrCode,
          serviceType: serviceType,
          countryId: countryId,
          otp: otp,
        }),
      }
    );

    const response2 = await res.json();
    // const response1 = await res.json();

    if (response2?.statusCode === 200) {
      if (response2) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...response2,
            token: token,
            iat: new Date(),
          },
        });
      }
      const mercentLogin = await login(dispatch, {
        mobileNumber: response2?.email || response2?.mobileNumber,
        password: password,
        type: type,
        token: token,
      });
      setLoading(false);

      //refetch();
      responseNotification(
        response2?.message?.replace("Operation", "Verification Successful.."),
        "success"
      );
      navigate("/business-create");
      //form.resetFields();
    } else {
      responseNotification(
        response2?.message?.replace(
          "Operation",
          "OTP is invalid. Please enter correct OTP"
        ),
        "warning"
      );
    }
  };
  // password show and hide
  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.iso2,
          label: country?.name,
        };
      }),
    });
  }, []);

  const fetchCountryDetails = useCallback((countryId: any) => {
    if (countryId) {
      try {
        setSingleCountry({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/country-state/country-details?countryCode=${countryId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCountry({
                loading: false,
                data: data?.data?.country,
              });
              setCountryCode(data?.data?.country?.phone_code);
            } else {
              setSingleCountry({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCountry({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleCountry({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchCountryDetails(countryId);
  }, [fetchCountryDetails, countryId]);

  const toggleShowPassword = () => {
    const password = document.querySelector("#password") as any;
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    password.classList.toggle("anticon-eye-invisible");
  };

  let startCount: any = useRef();
  let formRef: any = useRef();
  let inputRef: any = useRef();
  const startCountdown = useCallback(() => {
    startCount.current = setInterval(() => {
      setCount((val) => (val > 0 ? val - 1 : val));
    }, 1000);
  }, []);

  useEffect(() => {
    if (formType) {
      setSignup(formType);
      setOtherLogin(formType);
      setOtherSignup(formType);
    }
  }, [setSignup, setOtherLogin, setOtherSignup, formType]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "country") getCountryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions, type]);
  useEffect(() => {
    getCountryOptions();
  }, [getCountryOptions]);

  return (
    <>
      <div className="xl:max-w-screen-xs">
        <div className="">
          <div className="text-center flex flex-col">
            <span className="text-2xl text-gray-900 font-display font-semibold">
              Welcome
            </span>
            <span>
              To {type?.replace("JC_", "").toLowerCase()} Mearchant
              Login/Registration Panel{" "}
            </span>
          </div>
          {view === "login" ? (
            <>
              <div className="pt-2">
                <AllTypes type={type} />
              </div>
              <div className="mt-8">
                <Form
                  onFinish={MerchantLogin}
                  layout="vertical"
                  form={form}
                  size="large"
                >
                  <Input type="hidden" name="remember" defaultValue="true" />
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Region is required!",
                      },
                    ]}
                    name="businessCountry"
                    initialValue={countryId}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number is required!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full text-lg  border-gray-300 focus:outline-none focus:border-indigo-500"
                      // prefix={<MobileOutlined />}
                      placeholder="01XXXXXXXXX"
                      maxLength={11}
                      type="contact"
                      addonBefore={countryCode}
                      onChange={(e: any) => setMobileNumber(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Password" name="password" id="password">
                    <Input
                      className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      // prefix={<UnlockOutlined />}
                      suffix={
                        <EyeInvisibleOutlined
                          id="ddd"
                          onClick={toggleShowPassword}
                        />
                      }
                      placeholder="Enter your password"
                      type="password"
                      maxLength={11}
                      id="password"
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                  <div className="mt-6">
                    <Button
                      htmlType="submit"
                      size="large"
                      block
                      loading={loading}
                      disabled={(loading && !terms) || !type}
                      className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md h-14"
                      style={{ background: "#CD113B" }}
                    >
                      Login
                    </Button>
                  </div>
                  <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
                    Don’t have an account?{" "}
                    <Link
                      to="#"
                      className="cursor-pointer text-red-600 hover:text-red-800"
                      // onClick={() => setSignup(!signup)}
                      onClick={() => setView("siginup")}
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div className="mt-2 text-xs font-display text-gray-700 text-center cursor-pointer">
                    <span onClick={() => setVisible(true)}>
                      Forgot Password?
                    </span>
                  </div>
                  <div className="mt-2 text-xs font-display text-gray-700 text-center cursor-pointer">
                    <Link
                      to="#"
                      className="cursor-pointer text-blue-600 hover:text-blue-800"
                      //onClick={() => setOtherLogin(!otherLogin)}
                      onClick={() => setView("emailLogin")}
                    >
                      Other Login Option
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          ) : view === "siginup" ? (
            <>
              <div className="mt-8">
                <Form
                  onFinish={onSubmitOtp}
                  layout="vertical"
                  form={form}
                  size="large"
                >
                  <Input type="hidden" name="remember" defaultValue="true" />
                  <Form.Item
                    hasFeedback
                    label="Service Type"
                    rules={[
                      {
                        required: true,
                        message: "service type is required!",
                      },
                    ]}
                    name="serviceType"
                    initialValue={serviceType}
                  >
                    <Select
                      size="large"
                      placeholder="Select your service type"
                      onChange={(val) => setServiceType(val)}
                    >
                      <Select.Option value="JC_COMMERCE">
                        eCommerce
                      </Select.Option>
                      <Select.Option value="JC_GROCERY">GROCERY</Select.Option>
                      <Select.Option value="JC_FOOD">Food</Select.Option>
                      <Select.Option value="JC_MALL">MALL</Select.Option>
                      <Select.Option value="JC_PAYMENT">PAYMENT</Select.Option>
                      <Select.Option value="JC_BOOKING">BOOKING</Select.Option>
                      <Select.Option value="JC_RIDER">RIDER</Select.Option>
                      <Select.Option value="JC_PARCEL">PARCEL</Select.Option>
                      <Select.Option value="JC_MEDICINE">
                        MEDICINE
                      </Select.Option>
                      <Select.Option value="JC_AFFILIATE">
                        AFFILIATE
                      </Select.Option>
                      <Select.Option value="JC_MART">MART</Select.Option>
                      <Select.Option value="JC_GLOBAL">GLOBAL</Select.Option>
                      <Select.Option value="JC_LANDING">LANDING</Select.Option>
                      <Select.Option value="JC_FASHION">FASHION</Select.Option>
                      <Select.Option value="JC_PAY">PAY</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Region is required!",
                      },
                    ]}
                    name="businessCountry"
                    initialValue={countryId}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                  <Form.Item hasFeedback label="Full Name" name="fullName">
                    <Input
                      id="fullName"
                      type="text"
                      placeholder="Enter Full Name"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number is required!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full text-lg border-gray-300 focus:outline-none focus:border-indigo-500"
                      // prefix={<MobileOutlined />}
                      placeholder="01XXXXXXXXX"
                      maxLength={11}
                      addonBefore={countryCode}
                      type="contact"
                      onChange={(e: any) => setMobileNumber(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Password" name="password" id="password">
                    <Input
                      className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      // prefix={<UnlockOutlined />}
                      suffix={
                        <EyeInvisibleOutlined
                          id="ddd"
                          onClick={toggleShowPassword}
                        />
                      }
                      placeholder="Enter your password"
                      type="password"
                      maxLength={11}
                      id="password"
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      maxLength={11}
                      placeholder="Re-Type Password"
                      onChange={(e: any) => setConfirmPass(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Referrer Code" name="referrerCode">
                    <Input
                      id="referrerCode"
                      className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      placeholder="Enter referrer code (if any)"
                      type="text"
                      onChange={(e: any) => setRefferrCode(e.target.value)}
                    />
                  </Form.Item>
                  <div className="text-left">
                    <Checkbox
                      className="mr-2"
                      onChange={() => setIsReseller(true)}
                      defaultChecked={false}
                    >
                      IS Reseller
                    </Checkbox>
                  </div>
                  <div className="text-left">
                    <Checkbox
                      className="mr-2"
                      onChange={() => setTerms(!terms)}
                      defaultChecked={terms}
                    >
                      I agree with the terms and conditions
                    </Checkbox>
                  </div>

                  <div className="mt-2">
                    <Button
                      htmlType="submit"
                      size="large"
                      block
                      loading={loading}
                      disabled={!terms}
                      className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md h-14"
                      // style={{ background: "#CD113B" }}
                    >
                      Sign Up
                    </Button>
                    <Link
                      to="#"
                      className="cursor-pointer text-blue-600 hover:text-blue-800"
                      // onClick={() => setOtherSignup(!otherSignup)}
                      onClick={() => setView("emailSiginup")}
                    >
                      Other Signup Option
                    </Link>
                  </div>

                  <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
                    Already have an account?{" "}
                    <Link
                      to="#"
                      className="cursor-pointer text-red-600 hover:text-red-800"
                      onClick={() => setView("login")}
                    >
                      Login
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          ) : view === "otp" ? (
            <>
              <Form
                onFinish={onSubmitOtpVerify}
                layout="vertical"
                form={form}
                size="large"
              >
                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  initialValue={emailName || mobileNumber}
                  rules={[
                    {
                      required: true,
                      message: "Mobile or email is required!",
                    },
                  ]}
                >
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    // prefix={<MobileOutlined />}
                    placeholder={emailName || mobileNumber}
                    type="text"
                    value={emailName || mobileNumber}
                  />
                </Form.Item>
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "OTP is required!",
                    },
                  ]}
                >
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    placeholder="Enter Your OTP"
                    type="contact"
                    autoComplete={"off"}
                  />
                </Form.Item>
                <Button
                  disabled={!!count}
                  onClick={onSubmitOtp}
                  className={cn(
                    "text-sm bg-accent-4 text-accent-0 cursor-pointer rounded-tr-sm rounded-br-sm shadow-xs px-2 w-1/4 flex items-center whitespace-nowrap",
                    { "w-2/5 cursor-block  cursor-wait": count },
                    { "bg-accent-7": !count }
                  )}
                >
                  Resent {count ? `(${count})` : undefined}
                </Button>

                <div className="mt-2">
                  <Button
                    htmlType="submit"
                    size="large"
                    block
                    // loading={loading}
                    className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md h-14"
                    // style={{ background: "#CD113B" }}
                  >
                    Verify
                  </Button>
                </div>
              </Form>
            </>
          ) : view === "emailLogin" ? (
            <>
              <div className="pt-2">
                <AllTypes type={type} />
              </div>
              <div className="mt-8">
                <Form
                  onFinish={MerchantLogin}
                  layout="vertical"
                  form={form}
                  size="large"
                >
                  <Input type="hidden" name="remember" defaultValue="true" />
                  <Form.Item
                    label="Email Name"
                    name="emailName"
                    rules={[
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      placeholder="demo@gmail.com"
                      onChange={(e: any) => setEmailName(e.target.value)}
                      type="text"
                    />
                  </Form.Item>

                  <Form.Item label="Password" name="password" id="password">
                    <Input
                      className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      // prefix={<UnlockOutlined />}
                      suffix={
                        <EyeInvisibleOutlined
                          id="ddd"
                          onClick={toggleShowPassword}
                        />
                      }
                      placeholder="Enter your password"
                      type="password"
                      maxLength={11}
                      id="password"
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <div className="mt-6">
                    <Button
                      htmlType="submit"
                      size="large"
                      block
                      loading={loading}
                      disabled={(loading && !terms) || !type}
                      className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md h-14"
                      style={{ background: "#CD113B" }}
                    >
                      Login
                    </Button>
                  </div>
                  <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
                    Don’t have an account?{" "}
                    <Link
                      to="#"
                      className="cursor-pointer text-red-600 hover:text-red-800"
                      // onClick={() => setSignup(!signup)}
                      onClick={() => setView("siginup")}
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div className="mt-2 text-xs font-display text-gray-700 text-center cursor-pointer">
                    <span onClick={() => setVisible(true)}>
                      Forgot Password?
                    </span>
                  </div>
                  <div className="mt-2 text-xs font-display text-gray-700 text-center cursor-pointer">
                    <Link
                      to="#"
                      className="cursor-pointer text-blue-600 hover:text-blue-800"
                      //onClick={() => setOtherLogin(false)}
                      onClick={() => setView("login")}
                    >
                      Mobile Number Login
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          ) : view === "emailSiginup" ? (
            <>
              <div className="mt-8">
                <Form
                  onFinish={onSubmitOtp}
                  layout="vertical"
                  form={form}
                  size="large"
                >
                  <Input type="hidden" name="remember" defaultValue="true" />
                  <Form.Item
                    hasFeedback
                    label="Service Type"
                    rules={[
                      {
                        required: true,
                        message: "service type is required!",
                      },
                    ]}
                    name="serviceType"
                    initialValue={serviceType}
                  >
                    <Select
                      size="large"
                      placeholder="Select your service type"
                      onChange={(val) => setServiceType(val)}
                    >
                      <Select.Option value="JC_COMMERCE">
                        eCommerce
                      </Select.Option>
                      <Select.Option value="JC_GROCERY">GROCERY</Select.Option>
                      <Select.Option value="JC_FOOD">Food</Select.Option>
                      <Select.Option value="JC_MALL">MALL</Select.Option>
                      <Select.Option value="JC_PAYMENT">PAYMENT</Select.Option>
                      <Select.Option value="JC_BOOKING">BOOKING</Select.Option>
                      <Select.Option value="JC_RIDER">RIDER</Select.Option>
                      <Select.Option value="JC_PARCEL">PARCEL</Select.Option>
                      <Select.Option value="JC_MEDICINE">
                        MEDICINE
                      </Select.Option>
                      <Select.Option value="JC_AFFILIATE">
                        AFFILIATE
                      </Select.Option>
                      <Select.Option value="JC_MART">MART</Select.Option>
                      <Select.Option value="JC_GLOBAL">GLOBAL</Select.Option>
                      <Select.Option value="JC_LANDING">LANDING</Select.Option>
                      <Select.Option value="JC_FASHION">FASHION</Select.Option>
                      <Select.Option value="JC_PAY">PAY</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Region is required!",
                      },
                    ]}
                    name="businessCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                  <Form.Item hasFeedback label="Full Name" name="fullName">
                    <Input
                      id="fullName"
                      type="text"
                      placeholder="Enter Full Name"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email Name"
                    name="emailName"
                    rules={[
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      placeholder="demo@gmail.com"
                      onChange={(e: any) => setEmailName(e.target.value)}
                      type="text"
                    />
                  </Form.Item>

                  <Form.Item label="Password" name="password" id="password">
                    <Input
                      className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      suffix={
                        <EyeInvisibleOutlined
                          id="ddd"
                          onClick={toggleShowPassword}
                        />
                      }
                      placeholder="Enter your password"
                      type="password"
                      maxLength={11}
                      id="password"
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      maxLength={11}
                      placeholder="Re-Type Password"
                      onChange={(e: any) => setConfirmPass(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Referrer Code" name="referrerCode">
                    <Input
                      id="referrerCode"
                      className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                      placeholder="Enter referrer code (if any)"
                      type="text"
                      onChange={(e: any) => setRefferrCode(e.target.value)}
                    />
                  </Form.Item>

                  <div className="text-left">
                    <Checkbox
                      className="mr-2"
                      onChange={() => setIsReseller(true)}
                      defaultChecked={false}
                    >
                      IS Reseller
                    </Checkbox>
                  </div>
                  <div className="text-left">
                    <Checkbox
                      className="mr-2"
                      onChange={() => setTerms(!terms)}
                      defaultChecked={terms}
                    >
                      I agree with the terms and conditions
                    </Checkbox>
                  </div>

                  <div className="mt-2">
                    <Button
                      htmlType="submit"
                      size="large"
                      block
                      loading={loading}
                      disabled={!terms}
                      className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md h-14"
                      // style={{ background: "#CD113B" }}
                    >
                      Sign Up
                    </Button>

                    <Link
                      to="#"
                      className="cursor-pointer text-blue-600 hover:text-blue-800"
                      // onClick={() => setOtherSignup(!otherSignup)}
                      onClick={() => setView("siginup")}
                    >
                      Mobile Number Signup
                    </Link>
                  </div>

                  <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
                    Already have an account?{" "}
                    <Link
                      to="#"
                      className="cursor-pointer text-red-600 hover:text-red-800"
                      onClick={() => setView("login")}
                    >
                      Login
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          ) : (
            <>{/* <p>okey 2</p> */}</>
          )}
        </div>
      </div>
      <Modal
        title="Forgot Password"
        open={visible}
        onCancel={onClose}
        footer={false}
        centered
      >
        <ForgotPassword refetch={() => setVisible(!visible)} />
      </Modal>
    </>
  );
}
export default Login;
