import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import PersonalDetails from "../../components/MerchantProfile/PersonalDetails";

const PersonalDetailsPage = () => {
  return (
    <MerchantLayout>
      <PersonalDetails />
    </MerchantLayout>
  ); 
};

export default PersonalDetailsPage;
