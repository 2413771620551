import * as React from "react";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Space, TimePicker, Tooltip } from "antd";
import { responseNotification } from "../../utils/notify";
import axios from "axios";
import { ImageUploader, SubmitResetBtn } from "../common";
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import CheckableTag from "antd/lib/tag/CheckableTag";
import styles from "./Profile.module.css";
const businessDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
 
export const BusinessInfo: React.FC = () => {
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const shopId = merchantShopCheck?.[0].id || 0;
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [selectedDays, setSelectedDays] = useState<any[]>(["Sun"]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tinUrl, setTinUrl] = useState<string>();
  const [memorandumUrl, setMemorandumUrl] = useState<string>();
  const [resolutionUrl, setResolutionUrl] = useState<string>();
  const [tradeUrl, setTradeUrl] = useState<string>();
  const [binUrl, setBinUrl] = useState<string>();
  const [utilityUrl, setUtilityUrl] = useState<string>();
  const [bankUrl, setBankUrl] = useState<string>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();
  const [selectedStartTime, setSelectedStartTime] = useState("00:00");
  const [selectedCloseTime, setSelectedCloseTime] = useState("00:00");

  const [startUploadTin, setStartUploadTin] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadMemorandum, setStartUploadMemorandum] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadResolution, setStartUploadResolution] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadTrade, setStartUploadTrade] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBin, setStartUploadBin] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadUtility, setStartUploadUtility] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBank, setStartUploadBank] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadLogo, setStartUploadLogo] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadBanner, setStartUploadBanner] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [singleBusinessInfo, setSingleBusinessInfo] = useState<any>({
    loading: false,
    data: null,
  });

  useEffect(() => {
    if (startUploadTin === "Uploaded") {
      form?.submit();
    }
  }, [form, startUploadTin]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      merchantBusinessInfo: {
        attachBinNumber: data.attachBinNumber,
        attachTinDocuments: tinUrl,
        attachTradeLicence: data.attachTradeLicence,
        attachTradeLicenceDocument: tradeUrl,
        bankDocuments: bankUrl,
        bannerLogo: bannerUrl,
        binNumber: data.binNumber,
        businessDay: selectedDays?.map(
          (businessDay: any, businessDayIndex: any) => ({
            businessDayName: businessDay,
          })
        ),
        businessDescription: data.businessDescription,
        businessLogo: logoUrl,
        etinNo: data.etinNo,
        memorandum: memorandumUrl,
        resolution: resolutionUrl,
        shopClosingTime: selectedCloseTime,
        shopOpeningTime: selectedStartTime,
        utilityBillDocuments: utilityUrl,
      },
      shopId: shopId,
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/business-info`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...readyData }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Update Business Info Successfully", "success");
          //fetchBusinessInfoDetails(shopInfo?.id);
          form.resetFields();
          navigate("/merchant-profile");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);

  useEffect(() => {
    getMerchantShopCheck();
  }, [
    getMerchantShopCheck,
  ]);

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedDays = checked
      ? [...selectedDays, tag]
      : selectedDays.filter((t) => t !== tag);
    setSelectedDays(nextSelectedDays);
  };

  const fetchBusinessDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessInfo({
                loading: false,
                data: data?.data?.shop,
              });
              setTinUrl(data?.data?.shop?.merchantBusinessInfo?.attachTinDocuments);
              setTradeUrl(data?.data?.shop?.merchantBusinessInfo?.attachTradeLicenceDocument);
              setBankUrl(data?.data?.shop?.merchantBusinessInfo?.bankDocuments);
              setMemorandumUrl(data?.data?.shop?.merchantBusinessInfo?.memorandum);
              setResolutionUrl(data?.data?.shop?.merchantBusinessInfo?.resolution);
              setUtilityUrl(data?.data?.shop?.merchantBusinessInfo?.utilityBillDocuments);
              setLogoUrl(data?.data?.shop?.merchantBusinessInfo?.businessLogo);
              setBannerUrl(data?.data?.shop?.merchantBusinessInfo?.bannerLogo);
              setSelectedStartTime(data?.data?.shop?.merchantBusinessInfo?.shopOpeningTime);
              setSelectedCloseTime(data?.data?.shop?.merchantBusinessInfo?.shopClosingTime);
            } else {
              setSingleBusinessInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleBusinessInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []); 

  useEffect(() => {
    fetchBusinessDetails(shopId);
  }, [fetchBusinessDetails, shopId]);


  useEffect(() => {
    if (singleBusinessInfo?.data) {
      form.resetFields();
      setTinUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments);
      setTradeUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.attachTradeLicenceDocument);
      setBankUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bankDocuments);
      setMemorandumUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.memorandum);
      setResolutionUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.resolution);
      setUtilityUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.utilityBillDocuments);
      setLogoUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.businessLogo);
      setBannerUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bannerLogo);
      setSelectedStartTime(singleBusinessInfo?.data?.merchantBusinessInfo?.shopOpeningTime);
      setSelectedCloseTime(singleBusinessInfo?.data?.merchantBusinessInfo?.shopClosingTime);
    
    }
  }, [form, singleBusinessInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setTinUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments);
  };
  return (
    <div className="flex overflow-hidden flex-col bg-stone-50 pb-[16px] max-md:pb-24">
      <div className="flex flex-col self-center mt-5 max-w-full w-[1170px]">
        <div className="flex gap-2 self-start text-base font-bold text-zinc-800">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1b3ef0d8585173c8adc6c6327f514bc83b7a7832d2808a29568bcd269becc1e?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
            className="object-contain shrink-0 w-6 aspect-square"
            alt="Business info icon"
          />
          <div>Business Info</div>
        </div>
        <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...singleBusinessInfo?.data?.merchantBusinessInfo,
            etinNo: singleBusinessInfo?.data?.merchantBusinessInfo?.etinNo || "",
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off" 
        >
          <div className="w-1/2 grid grid-cols-1 gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Business Day"
                rules={[
                  {
                    required: false,
                    message: "Business Day is Required!",
                  },
                ]}
                name="name"
              >
                {/* setBusinessDay */}
                <Space size={[0, 12]} wrap>
                  {businessDays.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={selectedDays.includes(tag)}
                      onChange={(checked) => handleChange(tag, checked)}
                      className={styles.businessDay}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Space>
              </Form.Item>
            </div>
          </div>
          <div className="w-1/2 grid grid-cols-2 gap-x-6">
          <Form.Item
              hasFeedback
              label={`Shop Opening Time (${selectedStartTime})`}
            >
              <TimePicker
                format="HH:mm"
                //showNow={false}
                value={moment(selectedStartTime, "HH:mm")}
                suffixIcon={<DownOutlined />}
                onSelect={(value) => {
                  const startTimeString = moment(value as any).format("HH:mm");
                  setSelectedStartTime(startTimeString);
                }}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label={`Shop Closing Time`}
              rules={[
                {
                  required: true,
                  message: "Shop closing time is required!",
                },
              ]}
            >
              <TimePicker
                format="HH:mm"
                //showNow={false}
                value={moment(selectedCloseTime, "HH:mm")}
                suffixIcon={<DownOutlined />}
                onSelect={(value) => {
                  const timeCloseString = moment(value).format("HH:mm");
                  setSelectedCloseTime(timeCloseString);
                }}
              />
            </Form.Item>
          </div>
          <div className="w-1/2 grid gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Business Description"
                name="businessDescription"
              >
                <Input.TextArea
                  id="businessDescription"
                  placeholder="Enter Business Description"
                  rows={3}
                />
              </Form.Item>
            </div>
          </div>

          <div className="w-1/2 grid grid-cols-2 gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="E-Tin No"
                rules={[
                  {
                    required: false,
                    message: "E-Tin No is Required!",
                  },
                ]}
                name="etinNo"
              >
                <Input id="etinNo" type="text" placeholder="Enter E-Tin No" />
              </Form.Item>
            </div>

            <div className="">
              <Divider orientation="left">
                E-Tin No File
                <Tooltip placement="bottom" title={"E-Tin No File"}>
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>

              <ImageUploader
                imgUrl={tinUrl || ""}
                startUpload={startUploadTin}
                setStartUpload={(val: any) => setStartUploadTin(val)}
                setGeneratedImgUrl={(url: any) => setTinUrl(url)}
              />
            </div>
          </div>

          {shopInfo?.businessOperationType === "COMPANY" && (
            <>
              <div className="w-1/2 grid grid-cols-2 gap-x-6">
                <div className="">
                  <Divider orientation="left">
                    Memorandum
                    <Tooltip placement="bottom" title={"Memorandum "}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={memorandumUrl || ""}
                    startUpload={startUploadMemorandum}
                    setStartUpload={(val: any) => setStartUploadMemorandum(val)}
                    setGeneratedImgUrl={(url: any) => setMemorandumUrl(url)}
                  />
                </div>

                <div className="">
                  <Divider orientation="left">
                    Resolution
                    <Tooltip placement="bottom" title={"Resolution"}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={resolutionUrl || ""}
                    startUpload={startUploadResolution}
                    setStartUpload={(val: any) => setStartUploadResolution(val)}
                    setGeneratedImgUrl={(url: any) => setResolutionUrl(url)}
                  />
                </div>
              </div>
              <div className="w-1/2 grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Trade License"
                    rules={[
                      {
                        required: false,
                        message: "Trade License is Required!",
                      },
                    ]}
                    name="attachTradeLicence"
                  >
                    <Input
                      id="attachTradeLicence"
                      type="text"
                      placeholder="Enter Trade License"
                    />
                  </Form.Item>
                </div>

                <div className="">
                  <Divider orientation="left">
                    Trade License File
                    <Tooltip placement="bottom" title={"Trade License File"}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={tradeUrl || ""}
                    startUpload={startUploadTrade}
                    setStartUpload={(val: any) => setStartUploadTrade(val)}
                    setGeneratedImgUrl={(url: any) => setTradeUrl(url)}
                  />
                </div>
              </div>
              <div className="w-1/2 grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Bin No"
                    rules={[
                      {
                        required: false,
                        message: "Bin No is Required!",
                      },
                    ]}
                    name="binNumber"
                  >
                    <Input
                      id="binNumber"
                      type="text"
                      placeholder="Enter Bin No"
                    />
                  </Form.Item>
                </div>

                <div className="">
                  <Divider orientation="left">
                    Bin No File
                    <Tooltip placement="bottom" title={"Bin No"}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={binUrl || ""}
                    startUpload={startUploadBin}
                    setStartUpload={(val: any) => setStartUploadBin(val)}
                    setGeneratedImgUrl={(url: any) => setBinUrl(url)}
                  />
                </div>
              </div>
              <div className="w-1/2 grid grid-cols-2 gap-x-6">
                <div className="">
                  <Divider orientation="left">
                    Utility Bill
                    <Tooltip placement="bottom" title={"Utility Bill"}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={utilityUrl || ""}
                    startUpload={startUploadUtility}
                    setStartUpload={(val: any) => setStartUploadUtility(val)}
                    setGeneratedImgUrl={(url: any) => setUtilityUrl(url)}
                  />
                </div>

                <div className="">
                  <Divider orientation="left">
                    Bank Document
                    <Tooltip placement="bottom" title={"Bank Document"}>
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>

                  <ImageUploader
                    imgUrl={bankUrl || ""}
                    startUpload={startUploadBank}
                    setStartUpload={(val: any) => setStartUploadBank(val)}
                    setGeneratedImgUrl={(url: any) => setBankUrl(url)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="w-1/2 grid grid-cols-2 gap-x-6">
            <div className="">
              <Divider orientation="left">
                Business Logo
                <Tooltip
                  placement="bottom"
                  title={"Business Logo image should 1200px/500px (Ratio 2.4)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>

              <ImageUploader
                imgUrl={logoUrl || ""}
                startUpload={startUploadLogo}
                setStartUpload={(val: any) => setStartUploadLogo(val)}
                setGeneratedImgUrl={(url: any) => setLogoUrl(url)}
              />
            </div>

            <div className="">
              <Divider orientation="left">
                Business Banner
                <Tooltip
                  placement="bottom"
                  title={"Shop Banner image should 1200px/500px (Ratio 2.4)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>

              <ImageUploader
                imgUrl={bannerUrl || ""}
                startUpload={startUploadBanner}
                setStartUpload={(val: any) => setStartUploadBanner(val)}
                setGeneratedImgUrl={(url: any) => setBannerUrl(url)}
              />
            </div>
          </div>

          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUploadTin("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading || startUploadTin === "Uploading"}
            loading={loading || startUploadTin === "Uploading"}
          />
        </Form>
      </div>
        {/* <BusinessInfoForm /> */}
      </div>
    </div>
  );
};

export default BusinessInfo;
