import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopAffiliateProductList from "../../../components/Shop/Affiliate/index";

const ShopAffiliateProductPage = () => {
  return (
    <MainLayout>
      <ShopAffiliateProductList />
    </MainLayout>
  );
};

export default ShopAffiliateProductPage;
