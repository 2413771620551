import * as React from "react";
import { ActionButton } from "../MemberShipProfile/common/ActionButton";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Form, Input, Select, Switch, Tooltip } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { debounce } from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const BusinessAddressForm: React.FC = () => {
  const [formData, setFormData] = React.useState({
    businessName: "",
    businessLocation: "",
    address: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    landmark: "",
    contactName: "",
    contactNumber: "",
    email: "",
  });
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const shopId = merchantShopCheck?.[0].id || 0;
  const { businessLat } = useParams();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [markers, setMarkers] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const [countryId, setCountryId] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [stateId, setStateId] = useState<any>();

  const [businessCountryId, setBusinessCountryId] = useState<any>();
  const [swhCountryId, setSwhCountryId] = useState<any>();
  const [pwCountryId, setPwCountryId] = useState<any>();
  const [spCountryId, setSpCountryId] = useState<any>();
  const [srCountryId, setSrCountryId] = useState<any>();
  const [prCountryId, setPrCountryId] = useState<any>();
  const [ppCountryId, setPpCountryId] = useState<any>();

  const [businessStateId, setBusinessStateId] = useState<any>();
  const [prStateId, setPrStateId] = useState<any>("");
  const [srStateId, setSrStateId] = useState<any>("");
  const [ppStateId, setPpStateId] = useState<any>("");
  const [spStateId, setSpStateId] = useState<any>("");
  const [swhStateId, setSwhStateId] = useState<any>("");
  const [pwStateId, setPwStateId] = useState<any>("");

  const [businessCityId, setBusinessCityId] = useState<any>();
  const [swhCityId, setSwhCityId] = useState<any>();
  const [pwCityId, setPwCityId] = useState<any>();
  const [spCityId, setSpCityId] = useState<any>();
  const [ppCityId, setPpCityId] = useState<any>();
  const [srCityId, setSrCityId] = useState<any>();
  const [prCityId, setPrCityId] = useState<any>();

  const [businessSubUrbId, setBusinessSubUrbId] = useState<any>();
  const [prSubUrbId, setPrSubUrbId] = useState<any>("");
  const [srSubUrbId, setSrSubUrbId] = useState<any>("");
  const [ppSubUrbId, setPpSubUrbId] = useState<any>("");
  const [spSubUrbId, setSpSubUrbId] = useState<any>("");
  const [swhSubUrbId, setSwhSubUrbId] = useState<any>("");
  const [pwSubUrbId, setPwSubUrbId] = useState<any>("");

  const [businessAreaId, setBusinessAreaId] = useState<any>();
  const [prAreaId, setPrAreaId] = useState<any>("");
  const [srAreaId, setSrAreaId] = useState<any>("");
  const [ppAreaId, setPpAreaId] = useState<any>("");
  const [spAreaId, setSpAreaId] = useState<any>("");
  const [swhAreaId, setSwhAreaId] = useState<any>("");
  const [pwAreaId, setPwAreaId] = useState<any>("");

  const [businessAddress, setBusinessAddress] = useState<any>(true);
  const [setOnMap, setSetOnMap] = useState<any>(false);
  const [sellerWarehouseAddress, setSellerWarehouseAddress] =
    useState<any>(true);
  const [platformWarehouseAddress, setPlatformWarehouseAddress] =
    useState<any>(true);
  const [sellerPickupAddress, setSellerPickupAddress] = useState<any>(true);
  const [platformPickupAddress, setPlatformPickupAddress] = useState<any>(true);
  const [sellerReturnAddess, setSellerReturnAddess] = useState<any>(true);
  const [platformReturnAddress, setPlatformReturnAddress] = useState<any>(true);
  const [operationType, setOperationType] = useState<any>("INDIVIDUAL");
  const [businessType, setBusinessType] = useState<any>();
  const [serviceId, setServiceId] = useState<any>();
  const [companyId, setCompanyId] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [serviceOptions, setServiceOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [companyOptions, setCompanyOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [swhCountryOptions, setSwhCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prCityOptions, setPrCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srCityOptions, setSrCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppCityOptions, setPpCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spCityOptions, setSpCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhCityOptions, setSwhCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwCityOptions, setPwCityOptions] = useState<any>({
    list: [],
    loading: false,
  });

  // new address add start
  const [subUrbOptions, setSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prSubUrbOptions, setPrSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srSubUrbOptions, setSrSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppSubUrbOptions, setPpSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spSubUrbOptions, setSpSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhSubUrbOptions, setSwhSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwSubUrbOptions, setPwSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prAreaOptions, setPrAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srAreaOptions, setSrAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppAreaOptions, setPpAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spAreaOptions, setSpAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhAreaOptions, setSwhAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwAreaOptions, setPwAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });

  // new address add end

  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prStateOptions, setPrStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srStateOptions, setSrStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppStateOptions, setPpStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spStateOptions, setSpStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhStateOptions, setSwhStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwStateOptions, setPwStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [singleBusinessContactInfo, setSingleBusinessContactInfo] =
    useState<any>({
      loading: false,
      data: null,
    });

  const getCountryOptions = React.useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setSwhCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${businessCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [businessCountryId]
  );
  const getPrStateOptions = useCallback(
    async (key?: string) => {
      setPrStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${prCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [prCountryId]
  );
  const getSpStateOptions = useCallback(
    async (key?: string) => {
      setSpStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${spCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [spCountryId]
  );
  const getSwhStateOptions = useCallback(
    async (key?: string) => {
      setSwhStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${swhCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSwhStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [swhCountryId]
  );
  const getPwStateOptions = useCallback(
    async (key?: string) => {
      setPwStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${pwCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [pwCountryId]
  );
  const getPpStateOptions = useCallback(
    async (key?: string) => {
      setPpStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${ppCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPpStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [ppCountryId]
  );
  const getSrStateOptions = useCallback(
    async (stateName?: string) => {
      setSrStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${srCountryId}&page=0&limit=20` +
          (stateName ? `&stateName=${stateName}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSrStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [srCountryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${businessStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [businessStateId]
  );
  const getPrCityOptions = useCallback(
    async (key?: string) => {
      setPrCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${prStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [prStateId]
  );
  const getSwhCityOptions = useCallback(
    async (key?: string) => {
      setSwhCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${swhStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSwhCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [swhStateId]
  );
  const getPwCityOptions = useCallback(
    async (key?: string) => {
      setPwCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${pwStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [pwStateId]
  );
  const getSpCityOptions = useCallback(
    async (key?: string) => {
      setSpCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${spStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [spStateId]
  );
  const getPpCityOptions = useCallback(
    async (key?: string) => {
      setPpCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${ppStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPpCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [ppStateId]
  );
  const getSrCityOptions = useCallback(
    async (key?: string) => {
      setSrCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${srStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSrCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [srStateId]
  );

  //add new address start
  const getAreaOptions = useCallback(
    async (key?: string) => {
      setAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${businessCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [businessCityId]
  );
  const getPrAreaOptions = useCallback(
    async (key?: string) => {
      setPrAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${prCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [prCityId]
  );
  const getSwhAreaOptions = useCallback(
    async (key?: string) => {
      setSwhAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${swhCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSwhAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [swhCityId]
  );
  const getPwAreaOptions = useCallback(
    async (key?: string) => {
      setPwAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${pwCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [pwCityId]
  );
  const getSpAreaOptions = useCallback(
    async (key?: string) => {
      setSpAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${spCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [spCityId]
  );
  const getPpAreaOptions = useCallback(
    async (key?: string) => {
      setPpAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${ppCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPpAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [ppCityId]
  );
  const getSrAreaOptions = useCallback(
    async (key?: string) => {
      setSrAreaOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/area/all?cityId=${srCityId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSrAreaOptions({
        loading: false,
        list: res.data?.areas?.map((area: any) => ({
          label: area.name,
          value: area.id,
        })),
      });
    },
    [srCityId]
  );

  const getSubUrbOptions = useCallback(
    async (key?: string) => {
      setSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${businessAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [businessAreaId]
  );
  const getPrSubUrbOptions = useCallback(
    async (key?: string) => {
      setPrSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${prAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [prAreaId]
  );
  const getSwhSubUrbOptions = useCallback(
    async (key?: string) => {
      setSwhSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${swhAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSwhSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [swhAreaId]
  );
  const getPwSubUrbOptions = useCallback(
    async (key?: string) => {
      setPwSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${pwAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [pwAreaId]
  );
  const getSpSubUrbOptions = useCallback(
    async (key?: string) => {
      setSpSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${spAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [spAreaId]
  );
  const getPpSubUrbOptions = useCallback(
    async (key?: string) => {
      setPpSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${ppAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPpSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [ppAreaId]
  );
  const getSrSubUrbOptions = useCallback(
    async (key?: string) => {
      setSrSubUrbOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/suburb/all?areaId=${srAreaId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSrSubUrbOptions({
        loading: false,
        list: res.data?.suburbs?.map((suburb: any) => ({
          label: suburb.name,
          value: suburb.id,
        })),
      });
    },
    [srAreaId]
  );

  //add new address end

  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);

  useEffect(() => {
    getMerchantShopCheck();
  }, [getMerchantShopCheck]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      isSamePickUpAddress: sellerPickupAddress || true,
      isSamePlatformPickUpAddress: platformPickupAddress || true,
      isSamePlatformReturnAddress: platformReturnAddress || true,
      isSamePlatformWarehouseAddress: platformWarehouseAddress || true,
      isSameReturnAddress: sellerReturnAddess || true,
      isSameWarehouseAddress: sellerWarehouseAddress || true,
      merchantBusinessAndContactInfo: {
        address: data.businessAddress,
        city: businessCityId,
        cityId: businessCityId,
        businessAreaId: businessAreaId,
        businessSubUrbId: businessSubUrbId,
        contactPersonEmail: data.businessContractEmail,
        contactPersonName: data.businessContactPerson,
        contactPersonNumber: data.businessContractNumber,
        country: businessCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.businessNearestLandMark,
        postalCode: data.businessPostalCode,
        state: businessStateId,
      },
      pickupAddressInfos: {
        address: data.spAddress,
        city: spCityId,
        contactPersonEmail: data.spContactEmail,
        contactPersonName: data.spContactPerson,
        contactPersonNumber: data.spContractNumber,
        country: spCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.spNearestLandMark,
        postalCode: data.spPostalCode,
        state: spStateId,
        spAreaId: spAreaId,
        spSubUrbId: spSubUrbId,
      },
      platformPickUpAddress: {
        address: data.ppAddress,
        city: ppCityId,
        contactPersonEmail: data.ppContactEmail,
        contactPersonName: data.ppContactPerson,
        contactPersonNumber: data.ppContactNumber,
        country: ppCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.ppNearestLandMark,
        postalCode: data.ppPostalCode,
        state: ppStateId,
        ppAreaId: ppAreaId,
        ppSubUrbId: ppSubUrbId,
      },
      platformReturnAddress: {
        address: data.prAddress,
        city: prCityId,
        contactPersonEmail: data.prContactEmail,
        contactPersonName: data.prContactPerson,
        contactPersonNumber: data.prContractNumber,
        country: prCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.prNearestLandMark,
        postalCode: data.prPostalCode,
        state: prStateId,
        prAreaId: prAreaId,
        prSubUrbId: prSubUrbId,
      },
      platformWarehouseAddress: {
        address: data.pwAddress,
        city: pwCityId,
        contactPersonEmail: data.pwContactEmail,
        contactPersonName: data.pwContactPerson,
        contactPersonNumber: data.pwContractNumber,
        country: pwCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.pwNearestLandMark,
        postalCode: data.pwPostalCode,
        state: pwStateId,
        pwAreaId: pwAreaId,
        pwSubUrbId: pwSubUrbId,
      },
      returnAddressInfos: {
        address: data.srAddress,
        city: srCityId,
        contactPersonEmail: data.srContactEmail,
        contactPersonName: data.srContactPerson,
        contactPersonNumber: data.srContractNumber,
        country: srCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.srNearestLandMark,
        postalCode: data.srPostalCode,
        state: srStateId,
        srAreaId: srAreaId,
        srSubUrbId: srSubUrbId,
      },
      shopId: merchantShopCheck?.[0].id || 0,
      warehouseAddressInfos: {
        address: data.swhAddress,
        city: swhCityId,
        contactPersonEmail: data.swhContactEmail,
        contactPersonName: data.swhContactPerson,
        contactPersonNumber: data.swhContractNumber,
        country: swhCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.swhNearestLandMark,
        postalCode: data.swhPostalCode,
        state: swhStateId,
        swhAreaId: swhAreaId,
        swhSubUrbId: swhSubUrbId,
      },
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/business-contact-info`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...readyData }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification(" Update Successfully", "success");
          form.resetFields();
          navigate("/merchant-profile");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  }; 


  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "prState") getPrStateOptions(value);
          if (field === "srState") getSrStateOptions(value);
          if (field === "ppState") getPpStateOptions(value);
          if (field === "spState") getSpStateOptions(value);
          if (field === "pwState") getPwStateOptions(value);
          if (field === "swhState") getSwhStateOptions(value);
          if (field === "city") getCityOptions(value);
          if (field === "prCity") getPrCityOptions(value);
          if (field === "srCity") getSrCityOptions(value);
          if (field === "ppCity") getPpCityOptions(value);
          if (field === "spCity") getSpCityOptions(value);
          if (field === "swhCity") getSwhCityOptions(value);
          if (field === "pwCity") getPwCityOptions(value);
          if (field === "area") getAreaOptions(value);
          if (field === "prArea") getPrAreaOptions(value);
          if (field === "srArea") getSrAreaOptions(value);
          if (field === "ppArea") getPpAreaOptions(value);
          if (field === "spArea") getSpAreaOptions(value);
          if (field === "swhArea") getSwhAreaOptions(value);
          if (field === "pwArea") getPwAreaOptions(value);
          if (field === "subUrb") getSubUrbOptions(value);
          if (field === "prSubUrb") getPrSubUrbOptions(value);
          if (field === "srSubUrb") getSrSubUrbOptions(value);
          if (field === "ppSubUrb") getPpSubUrbOptions(value);
          if (field === "spSubUrb") getSpSubUrbOptions(value);
          if (field === "swhSubUrb") getSwhSubUrbOptions(value);
          if (field === "pwSubUrb") getPwSubUrbOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getCountryOptions,
    getCityOptions,
    getSpCityOptions,
    getSwhCityOptions,
    getPwCityOptions,
    getStateOptions,
    getSwhStateOptions,
    getPwStateOptions,
    getSpStateOptions,
    getPpCityOptions,
    getPpStateOptions,
    getSrCityOptions,
    getSrStateOptions,
    getPrCityOptions,
    getPrStateOptions,
    getAreaOptions,
    getPrAreaOptions,
    getSrAreaOptions,
    getPpAreaOptions,
    getSpAreaOptions,
    getSwhAreaOptions,
    getPwAreaOptions,
    getSubUrbOptions,
    getPrSubUrbOptions,
    getSrSubUrbOptions,
    getPpSubUrbOptions,
    getSpSubUrbOptions,
    getSwhSubUrbOptions,
    getPwSubUrbOptions,
    type,
  ]);
  useEffect(() => {
    getCountryOptions();
    getStateOptions();
    getSwhStateOptions();
    getCityOptions();
    getSwhCityOptions();
    getPwCityOptions();
    getPwStateOptions();
    getSpCityOptions();
    getPpCityOptions();
    getSrCityOptions();
    getAreaOptions();
    getSwhAreaOptions();
    getPwAreaOptions();
    getSpAreaOptions();
    getPpAreaOptions();
    getSrAreaOptions();
    getPrAreaOptions();
    getSubUrbOptions();
    getSwhSubUrbOptions();
    getPwSubUrbOptions();
    getPrSubUrbOptions();
    getSpSubUrbOptions();
    getPpSubUrbOptions();
    getSrSubUrbOptions();
    getSpStateOptions();
    getPpStateOptions();
    getSrStateOptions();
    getPrCityOptions();
    getPrStateOptions();
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getSpCityOptions,
    getSwhCityOptions,
    getPwCityOptions,
    getPpCityOptions,
    getPrCityOptions,
    getSrCityOptions,
    getAreaOptions,
    getSpAreaOptions,
    getSwhAreaOptions,
    getPwAreaOptions,
    getPpAreaOptions,
    getPrAreaOptions,
    getSrAreaOptions,
    getSubUrbOptions,
    getSpSubUrbOptions,
    getSwhSubUrbOptions,
    getPwSubUrbOptions,
    getPpSubUrbOptions,
    getPrSubUrbOptions,
    getSrSubUrbOptions,
    getPwStateOptions,
    getSpStateOptions,
    getPpStateOptions,
    getSrStateOptions,
    getPrStateOptions,
  ]);

  const fetchBusinessContactDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessContactInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessContactInfo({
                loading: false,
                data: data?.data?.shop,
              });
              setSellerWarehouseAddress("");
              setPlatformWarehouseAddress("");
              setSellerPickupAddress("");
              setPlatformPickupAddress("");
              setSellerReturnAddess("");
              setPlatformReturnAddress("");
              //setTinUrl(data?.data?.shop?.merchantBusinessInfo?.attachTinDocuments);
            } else {
              setSingleBusinessContactInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessContactInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleBusinessContactInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchBusinessContactDetails(shopId);
  }, [fetchBusinessContactDetails, shopId]);

  useEffect(() => {
    if (singleBusinessContactInfo?.data) {
      form.resetFields();
      // setTinUrl(singleBusinessContactInfo?.data?.merchantBusinessInfo?.attachTinDocuments);
      setCountryOptions({
        loading: false,
        list: [
          {
            value: singleBusinessContactInfo?.data?.country?.id,
            label: singleBusinessContactInfo?.data?.country?.name,
          },
          ...(countryOptions.list?.filter(
            (item) =>
              item.value !== singleBusinessContactInfo?.data?.country?.id
          ) || []),
        ],
      });
    }
  }, [form, singleBusinessContactInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    // setTinUrl(singleBusinessContactInfo?.data?.merchantBusinessInfo?.attachTinDocuments);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div className="flex overflow-hidden flex-col pb-8 bg-stone-50 max-md:pb-8">
      <main className="flex flex-col items-start self-center mt-5 max-w-full w-[50%]"> 
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...merchantShopCheck,
            ...singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo,
            businessAddress:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.address || "",
            postalCode:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.postalCode || "",
            businessNearestLandMark:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.nearestLandMark || "",
            businessContactPerson:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonName || "",
            businessContractNumber:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonNumber || "",
            businessContractEmail:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonEmail || "",
            //sellerWarehouseAddress
            swhAddress:
              singleBusinessContactInfo?.data?.warehouseAddressInfos?.address ||
              "",
            swhPostalCode:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.postalCode || "",
            swhNearestLandMark:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.nearestLandMark || "",
            swhContactPerson:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonName || "",
            swhContractNumber:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonNumber || "",
            swhContactEmail:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonEmail || "",
            //platformWarehouseAddress
            pwAddress:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.address || "",
            pwPostalCode:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.postalCode || "",
            pwNearestLandMark:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.nearestLandMark || "",
            pwContactPerson:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonName || "",
            pwContractNumber:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonNumber || "",
            pwContactEmail:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonEmail || "",
            //sellerPickupAddress
            spAddress:
              singleBusinessContactInfo?.data?.pickupAddressInfos?.address ||
              "",
            spPostalCode:
              singleBusinessContactInfo?.data?.pickupAddressInfos?.postalCode ||
              "",
            spNearestLandMark:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.nearestLandMark || "",
            spContactPerson:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonName || "",
            spContractNumber:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonNumber || "",
            spContactEmail:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonEmail || "",
            //platformPickupAddress
            ppAddress:
              singleBusinessContactInfo?.data?.platformPickUpAddress?.address ||
              "",
            ppPostalCode:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.postalCode || "",
            ppNearestLandMark:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.nearestLandMark || "",
            ppContactPerson:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonName || "",
            ppContactNumber:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonNumber || "",
            ppContactEmail:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonEmail || "",
            //sellerReturnAddess
            srAddress:
              singleBusinessContactInfo?.data?.returnAddressInfos?.address ||
              "",
            srPostalCode:
              singleBusinessContactInfo?.data?.returnAddressInfos?.postalCode ||
              "",
            srNearestLandMark:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.nearestLandMark || "",
            srContactPerson:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonName || "",
            srContractNumber:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonNumber || "",
            srContactEmail:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonEmail || "",
            //platformReturnAddress
            prAddress:
              singleBusinessContactInfo?.data?.platformReturnAddress?.address ||
              "",
            prPostalCode:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.postalCode || "",
            prNearestLandMark:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.nearestLandMark || "",
            prContactPerson:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonName || "",
            prContractNumber:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonNumber || "",
            prContactEmail:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonEmail || "",
            businessName: merchantShopCheck?.[0].name || "",
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          className="w-full"
        >
          <div className="flex gap-2 text-base font-bold text-zinc-800">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/15b6b75388e9e423a0f829053a1e53746c9350a00352a34da461af941fe2ad94?placeholderIfAbsent=true&apiKey=fca3da21012743ce8f0bf226c9b50cd7"
              alt=""
              className="object-contain shrink-0 w-6 aspect-square"
            />
            <h1>Business & Contact address</h1>
          </div> 

          <div className="flex flex-col items-start self-stretch py-7 pr-20 pl-8 mt-2.5 w-full bg-white rounded-md shadow-sm max-md:px-5 max-md:max-w-full">
            {/* Address Inputs */}
            <div className="grid grid-cols-2 gap-x-12">
             
                <Form.Item
                  hasFeedback
                  label="Business Address"
                  name="businessAddress"
                  style={{ minWidth: "100%" }}
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={true}
                    onChange={setBusinessAddress}
                  />
                </Form.Item>
             
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Business name"
                rules={[
                  {
                    required: false,
                    message: "Business name is Required!",
                  },
                ]}
                initialValue={shopInfo?.name}
                name="businessName"
              >
                <Input id="businessName" type="text" disabled placeholder="Enter Name" />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Full Address"
                rules={[
                  {
                    required: true,
                    message: "Full Address is Required!",
                  },
                ]}
                name="businessAddress"
              >
                <Input
                  id="businessAddress"
                  type="text"
                  placeholder="Enter Full Address"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`Country/Region`}
                rules={[
                  {
                    required: true,
                    message: "Region is required!",
                  },
                ]}
                name="businessCountry"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={(e) => setBusinessCountryId(e)}
                  onSearch={(val) => {
                    handleSearch?.(val, "country");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={countryOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`State/Province`}
                rules={[
                  {
                    required: true,
                    message: "State is required!",
                  },
                ]}
                name="businessState"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  onChange={(val) => setBusinessStateId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "state");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={stateOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`City`}
                rules={[
                  {
                    required: true,
                    message: "City is required!",
                  },
                ]}
                name="businessCity"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  onChange={(val) => setBusinessCityId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "city");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={cityOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`area`}
                rules={[
                  {
                    required: false,
                    message: "area is required!",
                  },
                ]}
                name="businessArea"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Area"
                  optionFilterProp="children"
                  onChange={(val) => setBusinessAreaId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "area");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={areaOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`Sub-Urb`}
                rules={[
                  {
                    required: false,
                    message: "Sub-Urb is required!",
                  },
                ]}
                name="businessSubUrb"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Sub-Urb"
                  optionFilterProp="children"
                  onChange={(val) => setBusinessSubUrbId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "subUrb");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={subUrbOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label={`Postal/Gip Code`}
                rules={[
                  {
                    required: false,
                    message: "Postal Code is required!",
                  },
                ]}
                name="postalCode"
              >
                <Input
                  id="postalCode"
                  type="number"
                  placeholder="Enter Postal Code"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Nearest Landmark"
                rules={[
                  {
                    required: false,
                    message: "Nearest LandMark is Required!",
                  },
                ]}
                name="businessNearestLandMark"
              >
                <Input
                  id="businessNearestLandMark"
                  type="text"
                  placeholder="Enter Nearest LandMark"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Contact Person Name"
                rules={[
                  {
                    required: false,
                    message: "Contact Person Name is Required!",
                  },
                ]}
                name="businessContactPerson"
              >
                <Input
                  id="businessContactPerson"
                  type="text"
                  placeholder="Enter Contact Person"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Contact Person Number"
                rules={[
                  {
                    required: false,
                    message: "Contact Person Number is Required!",
                  },
                ]}
                name="businessContractNumber"
              >
                <Input
                  id="businessContractNumber"
                  type="number"
                  placeholder="Enter Name"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                hasFeedback
                label="Email"
                rules={[
                  {
                    required: false,
                    message: "Email is Required!",
                  },
                ]}
                name="businessContractEmail"
              >
                <Input
                  id="businessContractEmail"
                  type="email"
                  placeholder="Enter Contact Email"
                />
              </Form.Item>
            </div>
            {sellerWarehouseAddress === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Seller Warehouse Address"
                    name="swAddress"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setSellerWarehouseAddress}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="swhAddress"
                  >
                    <Input
                      id="swhAddress"
                      type="text"
                      placeholder="Enter Address"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Status is required!",
                      },
                    ]}
                    name="SWHCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setSwhCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={swhCountryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="swhState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setSwhStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "swhState");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={swhStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="swhCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setSwhCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "swhCity");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={swhCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="swhArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setSwhAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "swhArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={swhAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="swhSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setSwhSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "swhSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={swhSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="swhPostalCode"
                  >
                    <Input
                      id="swhPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="swhNearestLandMark"
                  >
                    <Input
                      id="swhNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="swhContactPerson"
                  >
                    <Input
                      id="swhContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="swhContractNumber"
                  >
                    <Input
                      id="swhContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="swhContactEmail"
                  >
                    <Input
                      id="swhContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {platformWarehouseAddress === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Platform Warehouse Address"
                    name="Platform"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setPlatformWarehouseAddress}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="pwAddress"
                  >
                    <Input
                      id="pwAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Country is required!",
                      },
                    ]}
                    name="pwCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setPwCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="pwState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setPwStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "pwState");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={pwStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="pwCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setPwCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "pwCity");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={pwCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="pwArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setPwAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "pwArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={pwAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="pwSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setPwSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "pwSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={pwSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="pwPostalCode"
                  >
                    <Input
                      id="pwPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="pwNearestLandMark"
                  >
                    <Input
                      id="pwNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="pwContactPerson"
                  >
                    <Input
                      id="pwContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="pwContractNumber"
                  >
                    <Input
                      id="pwContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="pwContactEmail"
                  >
                    <Input
                      id="pwContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {sellerPickupAddress === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Seller Pickup Address"
                    name="isFreeDelivery"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setSellerPickupAddress}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="spAddress"
                  >
                    <Input
                      id="spAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Status is required!",
                      },
                    ]}
                    name="spCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Merchant"
                      optionFilterProp="children"
                      onChange={(val) => setSpCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="spState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setSpStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "state");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={spStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="spCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setSpCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "spCity");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={spCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="spArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setSpAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "spArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={spAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="spSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setSpSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "spSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={spSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="spPostalCode"
                  >
                    <Input
                      id="spPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="spNearestLandMark"
                  >
                    <Input
                      id="spNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="spContactPerson"
                  >
                    <Input
                      id="spContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="spContractNumber"
                  >
                    <Input
                      id="spContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="spContactEmail"
                  >
                    <Input
                      id="spContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {platformPickupAddress === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Platform Pickup Address"
                    name="platFromPickup"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setPlatformPickupAddress}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="ppAddress"
                  >
                    <Input
                      id="ppAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Country is required!",
                      },
                    ]}
                    name="ppCountryId"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setPpCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="ppState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setPpStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "ppState");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={ppStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="ppCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setPpCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "city");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={ppCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="ppArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setPpAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "ppArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={ppAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="ppSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setPpSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "ppSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={ppSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="ppPostalCode"
                  >
                    <Input
                      id="ppPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="ppNearestLandMark"
                  >
                    <Input
                      id="ppNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="ppContactPerson"
                  >
                    <Input
                      id="ppContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="ppContactNumber"
                  >
                    <Input
                      id="ppContactNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="ppContactEmail"
                  >
                    <Input
                      id="ppContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {sellerReturnAddess === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Seller Return Address"
                    name="sellerReturn"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setSellerReturnAddess}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="srAddress"
                  >
                    <Input
                      id="srAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Status is required!",
                      },
                    ]}
                    name="srCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(val) => setSrCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="srState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setSrStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "srState");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={srStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="srCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setSrCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "srCity");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={srCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="srArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setSrAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "srArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={srAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="srSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select SubUrb"
                      optionFilterProp="children"
                      onChange={(val) => setSrSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "srSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={srSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="srPostalCode"
                  >
                    <Input
                      id="srPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="srNearestLandMark"
                  >
                    <Input
                      id="srNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="srContactPerson"
                  >
                    <Input
                      id="srContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="srContractNumber"
                  >
                    <Input
                      id="srContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="srContactEmail"
                  >
                    <Input
                      id="srContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {platformReturnAddress === false && (
              <>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Platform Return Address"
                    name="prAddress"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                      onChange={setPlatformReturnAddress}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="prAddress"
                  >
                    <Input
                      id="prAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Status is required!",
                      },
                    ]}
                    name="prCountry"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Merchant"
                      optionFilterProp="children"
                      onChange={(val) => setPrCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="prState"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setPrStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "state");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={prStateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="prCity"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setPrCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "prCity");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={prCityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="prArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setPrAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "prArea");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={prAreaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="prSubUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setPrSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "prSubUrb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={prSubUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="prPostalCode"
                  >
                    <Input
                      id="prPostalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="prNearestLandMark"
                  >
                    <Input
                      id="prNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="prContactPerson"
                  >
                    <Input
                      id="prContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="prContractNumber"
                  >
                    <Input
                      id="prContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="prContactEmail"
                  >
                    <Input
                      id="prContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {/* Repeat AddressInput component for all form fields */}

            <div className="flex gap-3.5 mt-8">
              <button
                type="submit"
                className="px-5 py-4 bg-rose-700 rounded-md"
              >
                Create
              </button>
              <button
                type="reset"
                onClick={() => {
                  form?.resetFields();
                }}
                className="px-5 py-4 bg-amber-600 rounded-md"
              >
                Reset
              </button>
              {/* <ActionButton label="Save" variant="primary" onClick={() => {}} />
              <ActionButton
                label="Reset"
                variant="secondary"
                onClick={() => {
                  form?.resetFields();
                }}
              /> */}
            </div>
          </div>

          <div className="mt-8 space-y-4">
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Seller Warehouse Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setSellerWarehouseAddress}
                />
              </div>
            </div>
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Platform Warehouse Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setPlatformWarehouseAddress}
                />
              </div>
            </div>
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Seller Pickup Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setSellerPickupAddress}
                />
              </div>
            </div>
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Platform Pickup Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setPlatformPickupAddress}
                />
              </div>
            </div>
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Seller Return Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setSellerReturnAddess}
                />
              </div>
            </div>
            <div className="flex gap-5 justify-start items-center">
              <div className="flex flex-col w-1/4">
                <div className="text-base font-bold text-zinc-800">
                  Platform Return Address
                </div>
                <div className="text-xs font-medium">
                  Same as business Address
                </div>
              </div>
              <div className="flex">
                <Tooltip placement="bottom" title={"Same as business Address"}>
                  <QuestionCircleOutlined className="px-12 -mt-2" />
                </Tooltip>
              </div>
              <div className="flex">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={true}
                  onChange={setPlatformReturnAddress}
                />
              </div>
            </div>
          </div>
        </Form>
      </main>
    </div>
  );
};

export default BusinessAddressForm;
