import * as React from "react";
import styles from "./common/Profile.module.css";
import { UserBanner } from "./common/UserBanner";
import { Card, Checkbox, Col, Radio, Row } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined, WechatOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { responseNotification } from "../../utils/notify";

export default function SellerDashboard() {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [getBankType, setGetBankType] = useState("BANKING");
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const shopId = merchantShopCheck?.[0].id;
  const [businessContractCheck, setBusinessContractCheck] = useState<any>();
  console.log('businessContractCheck ===',businessContractCheck);
  
  const [merchantDetailsInfo, setMerchantDetailsInfo] = useState<any>();
  const [personalDetailsCheck, setPersonalDetailsCheck] = useState<any>();
  console.log('merchantDetailsInfo ==',merchantDetailsInfo);
  console.log('personalDetailsCheck ==',personalDetailsCheck);

  const [addBankInfoCheck, setAddBankInfoCheck] = useState<any>();
  const [liveChatCheck, setLiveChatCheck] = useState<any>();

  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);
  const getBusinessContractCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${merchantShopCheck?.[0].id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBusinessContractCheck(res?.data?.shop);
  }, [merchantShopCheck?.[0].id]);
  const fetchMerchantDetails = useCallback(() => {
    try {
      setPersonalDetailsCheck({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setPersonalDetailsCheck({
              loading: false,
              data: data?.data?.merchant?.merchantPersonalDetail,
            });
            setMerchantDetailsInfo(data?.data?.merchant);
          } else {
            setPersonalDetailsCheck({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setPersonalDetailsCheck({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setPersonalDetailsCheck({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [token]);

  const fetchMerchantBankDetails = useCallback(() => {
    try {
      setAddBankInfoCheck({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/getAllBank?shopId=${merchantShopCheck?.[0].id}&bankingType=${getBankType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAddBankInfoCheck({
              loading: false,
              data: data?.data?.shopBanks,
            });
          } else {
            setAddBankInfoCheck({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setAddBankInfoCheck({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setAddBankInfoCheck({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [merchantShopCheck?.[0].id]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, getBankType]);

  useEffect(() => {
    getMerchantShopCheck();
    fetchMerchantDetails();
  }, [
    getMerchantShopCheck,
    ,
    fetchMerchantDetails,
    ,
  ]);
  useEffect(() => {
    getBusinessContractCheck();
  }, [getBusinessContractCheck, merchantShopCheck?.[0].id]);


  useEffect(() => {
    fetchMerchantBankDetails();
  }, [fetchMerchantBankDetails, merchantShopCheck?.[0].id]);

  return (
    <div className="flex overflow-hidden flex-col items-center bg-stone-50 pb-[20px] max-md:pb-24">
      <UserBanner
        userId={merchantDetailsInfo?.walletId || "JM 5421547"}
        userName={
          merchantDetailsInfo?.merchantPersonalDetail?.name || "New User"
        }
        membershipInfo={{
          title: "Welcome to Jachai Seller Center",
          description: "Explore US of wih a new seller and more benefits",
          ctaText: <Link to="/membership-upgrade">Explore</Link>,
        }}
        verificationStatus="our team is review your Documents. Expect a response within 72 hours. Thank you for your patience."
      />

      <div className="flex flex-col gap-8 w-[970px] max-w-full mt-8">
        <Row className="w-full">
          <Col className="w-1/2 ">
            <Card className={styles.cardMain}>
              <div className={styles.cardListCheck}>
                {merchantShopCheck?.length > 0 ? (
                  <>
                    <Checkbox checked></Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox></Checkbox>
                  </>
                )}
              </div>
              <div className={styles.cardListInfo}>
                {merchantShopCheck?.length > 0 ? (
                  <>
                    <Link to="/business-create">Create Business</Link>
                  </>
                ) : (
                  <>
                    <Link to="/business-create">Create Business</Link>
                  </>
                )}
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
          <Col className="w-1/2">
            <Card className={styles.cardMainRight}>
              <div className={styles.cardListCheck}>
                {businessContractCheck?.merchantBusinessAndContactInfo
                  ?.address ? (
                  <>
                    <Checkbox checked></Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox></Checkbox>
                  </>
                )}
              </div>
              <div className={styles.cardListInfo}>
                {businessContractCheck?.merchantBusinessAndContactInfo
                  ?.address ? (
                  <>
                    <Link to="/business-contract-address">Business & Contact Address</Link>
                  </>
                ) : (
                  <>
                    <Link to="/business-contract-address">
                      Business & Contact Address
                    </Link>
                  </>
                )}
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="w-full">
          <Col className="w-1/2 ">
            <Card className={styles.cardMain}>
              <div className={styles.cardListCheck}>
                {businessContractCheck?.merchantBusinessInfo ? (
                  <>
                    <Checkbox checked></Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox></Checkbox>
                  </>
                )}
              </div>
              <div className={styles.cardListInfo}>
                {businessContractCheck?.merchantBusinessInfo ? (
                  <>
                    <Link to="/business-day-info">Business Info </Link>
                  </>
                ) : (
                  <>
                    <Link to="/business-day-info">Business Info</Link>
                  </>
                )}
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
          <Col className="w-1/2">
            <Card className={styles.cardMainRight}>
              <div className={styles.cardListCheck}>
                {personalDetailsCheck !==null ? (
                  <>
                    <Checkbox checked></Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox></Checkbox>
                  </>
                )}
              </div>
              <div className={styles.cardListInfo}>
                {personalDetailsCheck ? (
                  <>
                    <Link to="/personal-details">Personal Details</Link>
                  </>
                ) : (
                  <>
                    <Link to="/personal-details">Personal Details</Link>
                  </>
                )}
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="w-full">
          <Col className="w-1/2 ">
            <Card className={styles.cardMain}>
              <div className={styles.cardListCheck}>
                {addBankInfoCheck?.data?.length > 0 ? (
                  <>
                    <Checkbox checked></Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox></Checkbox>
                  </>
                )}
              </div>
              <div className={styles.cardListInfo}>
                {addBankInfoCheck?.data?.length > 0 ? (
                  <>
                    <Link to="/bank-info">Add Bank</Link>
                  </>
                ) : (
                  <>
                    <Link to="/bank-info">Add Bank</Link>
                  </>
                )}
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
          <Col className="w-1/2">
            <Card className={styles.cardMainRight}>
              <div className={styles.cardListCheck}>
                {/* <Checkbox checked></Checkbox> */}
                <WechatOutlined />
              </div>
              <div className={styles.cardListInfo}>
                <Link to="javascript:void(Tawk_API.toggle())">Live Chat</Link>
              </div>
              <div className={styles.cardListArro}>
                <RightOutlined />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

{merchantShopCheck?.length > 0 && businessContractCheck?.merchantBusinessAndContactInfo
                  ?.address && businessContractCheck?.merchantBusinessInfo && personalDetailsCheck !==null && (
  <>
  

      <div className="flex flex-col justify-center items-center px-20 py-14 mt-20 max-w-full text-lg font-bold text-center bg-white rounded-xl w-[965px] max-md:px-5 max-md:mt-10">
        <div className="flex flex-col items-center max-w-full w-[471px]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/91b66f6db19d995d1209b942fd7ab8260a6ed4cd28032f81fd08fb5a8af861c6?placeholderIfAbsent=true&apiKey=61022fe3774e4aa287a6264de6b79c87"
            alt="Create business illustration"
            className="object-contain max-w-full aspect-[1.15] w-[164px]"
          />
          <div className="self-stretch mt-5 text-zinc-500 max-md:max-w-full">
          It takes about 2-5 working days.
          </div>
          <button className="px-12 py-3.5 mt-11 max-w-full text-white bg-slate-300 rounded-md w-[330px] max-md:px-5 max-md:mt-10">
          Under Review
          </button>
        </div>
      </div>
      </>
)}

    </div>
  );
}
