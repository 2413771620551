// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Service_root__nIUuQ {

  --tw-bg-opacity: 1;

  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));

  padding-top: 5rem;

  padding-bottom: 5rem
}

.Service_link__4qbdq {
  & > svg {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  & > svg {

    transition-duration: 75ms
  }
  & > svg {

    transition-timing-function: linear
  }

  &:hover > svg {

    --tw-scale-x: 1.1;

    --tw-scale-y: 1.1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/Service/Service.module.css"],"names":[],"mappings":"AACE;;EAAA,kBAAuB;;EAAvB,4DAAuB;;EAAvB,iBAAuB;;EAAvB;AAAuB;;AAGzB;EAEI;;IAAA;EAAwC;EAAxC;;IAAA;EAAwC;EAAxC;;IAAA;EAAwC;;EAIxC;;IAAA,iBAAgB;;IAAhB,iBAAgB;;IAAhB;EAAgB;AAEpB","sourcesContent":[".root {\n  @apply py-20 bg-gray-50;\n}\n\n.link {\n  & > svg {\n    @apply transform duration-75 ease-linear;\n  }\n\n  &:hover > svg {\n    @apply scale-110;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Service_root__nIUuQ`,
	"link": `Service_link__4qbdq`
};
export default ___CSS_LOADER_EXPORT___;
