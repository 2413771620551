import {
  EditOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
interface EmptyProps {
  height?: string;
  data?: string;
}
import { debounce } from "lodash";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router-dom";
import MapBox from "../../common/MapBox";
import BreadCrumb from "../../Layouts/Breadcrumb";
import ShopLayout from "../Layout";

const BusinessContractAddress = () => {
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = shopInfo?.id;
  const { businessLat } = useParams();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [markers, setMarkers] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const [businessCountryId, setBusinessCountryId] = useState<any>();

  const [swhCountryId, setSwhCountryId] = useState<any>(0);
  const [pwCountryId, setPwCountryId] = useState<any>(0);
  const [spCountryId, setSpCountryId] = useState<any>(0);
  const [srCountryId, setSrCountryId] = useState<any>(0);
  const [countryId, setCountryId] = useState<any>(0);
  const [prCountryId, setPrCountryId] = useState<any>(0);
  const [ppCountryId, setPpCountryId] = useState<any>(0);
  const [businessCityId, setBusinessCityId] = useState<any>(0);
  const [swhCityId, setSwhCityId] = useState<any>(0);
  const [pwCityId, setPwCityId] = useState<any>(0);
  const [spCityId, setSpCityId] = useState<any>(0);
  const [ppCityId, setPpCityId] = useState<any>(0);
  const [srCityId, setSrCityId] = useState<any>(0);
  const [prCityId, setPrCityId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>();
  const [businessStateId, setBusinessStateId] = useState<any>();
  const [prStateId, setPrStateId] = useState<any>("");
  const [srStateId, setSrStateId] = useState<any>("");
  const [ppStateId, setPpStateId] = useState<any>("");
  const [spStateId, setSpStateId] = useState<any>("");
  const [swhStateId, setSwhStateId] = useState<any>("");
  const [pwStateId, setPwStateId] = useState<any>("");
  const [businessAddress, setBusinessAddress] = useState<any>(true);
  const [setOnMap, setSetOnMap] = useState<any>(false);
  const [sellerWarehouseAddress, setSellerWarehouseAddress] =
    useState<any>(true);
  const [platformWarehouseAddress, setPlatformWarehouseAddress] =
    useState<any>(true);
  const [sellerPickupAddress, setSellerPickupAddress] = useState<any>(true);
  const [platformPickupAddress, setPlatformPickupAddress] = useState<any>(true);
  const [sellerReturnAddess, setSellerReturnAddess] = useState<any>(true);
  const [platformReturnAddress, setPlatformReturnAddress] = useState<any>(true);
  const [operationType, setOperationType] = useState<any>("INDIVIDUAL");
  const [businessType, setBusinessType] = useState<any>();
  const [serviceId, setServiceId] = useState<any>();
  const [companyId, setCompanyId] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [serviceOptions, setServiceOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [companyOptions, setCompanyOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [swhCountryOptions, setSwhCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwCountryOptions, setPwCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spCountryOptions, setSpCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppCountryOptions, setPpCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srCountryOptions, setSrCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prCountryOptions, setPrCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prCityOptions, setPrCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srCityOptions, setSrCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppCityOptions, setPpCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spCityOptions, setSpCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhCityOptions, setSwhCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwCityOptions, setPwCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [prStateOptions, setPrStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [srStateOptions, setSrStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [ppStateOptions, setPpStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [spStateOptions, setSpStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [swhStateOptions, setSwhStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [pwStateOptions, setPwStateOptions] = useState<any>({
    list: [],
    loading: false,
  }); 
  const [singleBusinessContactInfo, setSingleBusinessContactInfo] =
    useState<any>({
      loading: false,
      data: null,
    });


  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setSwhCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setPwCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setSpCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setPpCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setSrCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
    setPrCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${businessCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [businessCountryId]
  );
  const getPrStateOptions = useCallback(
    async (key?: string) => {
      setPrStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${prCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [prCountryId]
  );
  const getSpStateOptions = useCallback(
    async (key?: string) => {
      setSpStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${spCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [spCountryId]
  );
  const getSwhStateOptions = useCallback(
    async (key?: string) => {
      setSwhStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${swhCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSwhStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [swhCountryId]
  );
  const getPwStateOptions = useCallback(
    async (key?: string) => {
      setPwStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${pwCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [pwCountryId]
  );
  const getPpStateOptions = useCallback(
    async (key?: string) => {
      setPpStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${ppCountryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPpStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [ppCountryId]
  );
  const getSrStateOptions = useCallback(
    async (stateName?: string) => {
      setSrStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${srCountryId}&page=0&limit=20` +
          (stateName ? `&stateName=${stateName}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSrStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [srCountryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${businessStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [businessStateId]
  );
  const getPrCityOptions = useCallback(
    async (key?: string) => {
      setPrCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${prStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPrCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [prStateId]
  );
  const getSwhCityOptions = useCallback(
    async (key?: string) => {
      setSwhCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${swhStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSwhCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [swhStateId]
  );
  const getPwCityOptions = useCallback(
    async (key?: string) => {
      setPwCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${pwStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPwCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [pwStateId]
  );
  const getSpCityOptions = useCallback(
    async (key?: string) => {
      setSpCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${spStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSpCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [spStateId]
  );
  const getPpCityOptions = useCallback(
    async (key?: string) => {
      setPpCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${ppStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPpCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [ppStateId]
  );
  const getSrCityOptions = useCallback(
    async (key?: string) => {
      setSrCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${srStateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSrCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [srStateId]
  );

  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);

  useEffect(() => {
    getMerchantShopCheck();
  }, [getMerchantShopCheck]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      isSamePickUpAddress: sellerPickupAddress || true,
      isSamePlatformPickUpAddress: platformPickupAddress || true,
      isSamePlatformReturnAddress: platformReturnAddress || true,
      isSamePlatformWarehouseAddress: platformWarehouseAddress || true,
      isSameReturnAddress: sellerReturnAddess || true,
      isSameWarehouseAddress: sellerWarehouseAddress || true,
      merchantBusinessAndContactInfo: {
        address: data.businessAddress,
        city: businessCityId,
        cityId: businessCityId,
        contactPersonEmail: data.businessContractEmail,
        contactPersonName: data.businessContactPerson,
        contactPersonNumber: data.businessContractNumber,
        country: businessCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.businessNearestLandMark,
        postalCode: data.postalCode,
        state: businessStateId,
      },
      pickupAddressInfos: {
        address: data.spAddress,
        city: spCityId,
        contactPersonEmail: data.spContactEmail,
        contactPersonName: data.spContactPerson,
        contactPersonNumber: data.spContractNumber,
        country: spCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.spNearestLandMark,
        postalCode: data.spPostalCode,
        state: spStateId,
      },
      platformPickUpAddress: {
        address: data.ppAddress,
        city: ppCityId,
        contactPersonEmail: data.ppContactEmail,
        contactPersonName: data.ppContactPerson,
        contactPersonNumber: data.ppContactNumber,
        country: ppCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.ppNearestLandMark,
        postalCode: data.ppPostalCode,
        state: ppStateId,
      },
      platformReturnAddress: {
        address: data.prAddress,
        city: prCityId,
        contactPersonEmail: data.prContactEmail,
        contactPersonName: data.prContactPerson,
        contactPersonNumber: data.prContractNumber,
        country: prCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.prNearestLandMark,
        postalCode: data.prPostalCode,
        state: prStateId,
      },
      platformWarehouseAddress: {
        address: data.pwAddress,
        city: pwCityId,
        contactPersonEmail: data.pwContactEmail,
        contactPersonName: data.pwContactPerson,
        contactPersonNumber: data.pwContractNumber,
        country: pwCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.pwNearestLandMark,
        postalCode: data.pwPostalCode,
        state: pwStateId,
      },
      returnAddressInfos: {
        address: data.srAddress,
        city: srCityId,
        contactPersonEmail: data.srContactEmail,
        contactPersonName: data.srContactPerson,
        contactPersonNumber: data.srContractNumber,
        country: srCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.srNearestLandMark,
        postalCode: data.srPostalCode,
        state: srStateId,
      },
      shopId: shopInfo?.id,
      warehouseAddressInfos: {
        address: data.swhAddress,
        city: swhCityId,
        contactPersonEmail: data.swhContactEmail,
        contactPersonName: data.swhContactPerson,
        contactPersonNumber: data.swhContractNumber,
        country: swhCountryId,
        // "location": {
        //   "coordinates": [
        //     0
        //   ],
        //   "type": "string",
        //   "x": 0,
        //   "y": 0
        // },
        nearestLandMark: data.swhNearestLandMark,
        postalCode: data.swhPostalCode,
        state: swhStateId,
      },
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/business-contact-info`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...readyData }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification(" Update Successfully", "success");
          form.resetFields();
         // window.location.reload();
          navigate(-1);
          //navigate("/business/contact-address");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "prState") getPrStateOptions(value);
          if (field === "srState") getSrStateOptions(value);
          if (field === "ppState") getPpStateOptions(value);
          if (field === "spState") getSpStateOptions(value);
          if (field === "pwState") getPwStateOptions(value);
          if (field === "swhState") getSwhStateOptions(value);
          if (field === "city") getCityOptions(value);
          if (field === "prCity") getPrCityOptions(value);
          if (field === "srCity") getSrCityOptions(value);
          if (field === "ppCity") getPpCityOptions(value);
          if (field === "spCity") getSpCityOptions(value);
          if (field === "swhCity") getSwhCityOptions(value);
          if (field === "pwCity") getPwCityOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getCountryOptions,
    getCityOptions,
    getSpCityOptions,
    getSwhCityOptions,
    getPwCityOptions,
    getStateOptions,
    getSwhStateOptions,
    getPwStateOptions,
    getSpStateOptions,
    getPpCityOptions,
    getPpStateOptions,
    getSrCityOptions,
    getSrStateOptions,
    getPrCityOptions,
    getPrStateOptions,
    type,
  ]);
  useEffect(() => {
    getCountryOptions();
    getCityOptions();
    getStateOptions();
    getSwhCityOptions();
    getSwhStateOptions();
    getPwCityOptions();
    getPwStateOptions();
    getSpCityOptions();
    getSpStateOptions();
    getPpCityOptions();
    getPpStateOptions();
    getSrCityOptions();
    getSrStateOptions();
    getPrCityOptions();
    getPrStateOptions();
  }, [
    getCountryOptions,
    getCityOptions,
    getSpCityOptions,
    getSwhCityOptions,
    getStateOptions,
    getPwCityOptions,
    getPwStateOptions,
    getSpStateOptions,
    getPpCityOptions,
    getPpStateOptions,
    getSrCityOptions,
    getSrStateOptions,
    getPrCityOptions,
    getPrStateOptions,
  ]);

  const fetchBusinessContactDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessContactInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessContactInfo({
                loading: false,
                data: data?.data?.shop,
              });
              setCountryOptions(
                data?.data?.shop?.merchantBusinessAndContactInfo?.country
              );
              setBusinessCountryId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.country
              );
              setStateOptions(
                data?.data?.shop?.merchantBusinessAndContactInfo?.state
              );
              setBusinessStateId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.state
              );
              setCityOptions(
                data?.data?.shop?.merchantBusinessAndContactInfo?.city
              );
              setBusinessCityId(
                data?.data?.shop?.merchantBusinessAndContactInfo?.city
              );
              setSwhCountryId(data?.data?.shop?.warehouseAddressInfos?.country);
              setSwhStateId(data?.data?.shop?.warehouseAddressInfos?.state);
              setSwhCityId(data?.data?.shop?.warehouseAddressInfos?.city);
              setPwCountryId(
                data?.data?.shop?.platformWarehouseAddress?.country
              );
              setPwStateId(data?.data?.shop?.platformWarehouseAddress?.state);
              setPwCityId(data?.data?.shop?.platformWarehouseAddress?.city);
              setSpCountryId(data?.data?.shop?.pickupAddressInfos?.country);
              setSpStateId(data?.data?.shop?.pickupAddressInfos?.state);
              setSpCityId(data?.data?.shop?.pickupAddressInfos?.city);
              setPpCountryId(data?.data?.shop?.platformPickUpAddress?.country);
              setPpStateId(data?.data?.shop?.platformPickUpAddress?.state);
              setPpCityId(data?.data?.shop?.platformPickUpAddress?.city);
              setSrCountryId(data?.data?.shop?.returnAddressInfos?.country);
              setSrStateId(data?.data?.shop?.returnAddressInfos?.state);
              setSrCityId(data?.data?.shop?.returnAddressInfos?.city);
              setPrCountryId(data?.data?.shop?.platformReturnAddress?.country);
              setPrStateId(data?.data?.shop?.platformReturnAddress?.state);
              setPrCityId(data?.data?.shop?.platformReturnAddress?.city);
              setSellerWarehouseAddress("");
              setPlatformWarehouseAddress("");
              setSellerPickupAddress("");
              setPlatformPickupAddress("");
              setSellerReturnAddess("");
              setPlatformReturnAddress("");
              //setTinUrl(data?.data?.shop?.merchantBusinessInfo?.attachTinDocuments);
            } else {
              setSingleBusinessContactInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessContactInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleBusinessContactInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);


  useEffect(() => {
    fetchBusinessContactDetails((route as any)?.shopId);
  }, [fetchBusinessContactDetails, route]);

  useEffect(() => {
    if (singleBusinessContactInfo?.data) {
      form.resetFields();
      //setBusinessCountryId(singleBusinessContactInfo?.data?.country);
      setCountryOptions(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.country
      );
      setBusinessCountryId(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.country
      );
      setStateOptions(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.state
      );
      setBusinessStateId(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.state
      );
      setCityOptions(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.city
      );
      setBusinessCityId(
        singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo?.city
      );
      setSwhCountryOptions(
        singleBusinessContactInfo?.data?.warehouseAddressInfos?.country
      );
      setSwhStateOptions(
        singleBusinessContactInfo?.data?.warehouseAddressInfos?.state
      );
      setSwhCityOptions(
        singleBusinessContactInfo?.data?.warehouseAddressInfos?.city
      );
      setPwCountryOptions(
        singleBusinessContactInfo?.data?.platformWarehouseAddress?.country
      );
      setPwStateOptions(
        singleBusinessContactInfo?.data?.platformWarehouseAddress?.state
      );
      setPwCityOptions(
        singleBusinessContactInfo?.data?.platformWarehouseAddress?.city
      );
      setSpCountryOptions(
        singleBusinessContactInfo?.data?.pickupAddressInfos?.country
      );
      setSpStateOptions(
        singleBusinessContactInfo?.data?.pickupAddressInfos?.state
      );
      setSpCityOptions(
        singleBusinessContactInfo?.data?.pickupAddressInfos?.city
      );
      setPpCountryOptions(
        singleBusinessContactInfo?.data?.platformPickUpAddress?.country
      );
      setPpStateOptions(
        singleBusinessContactInfo?.data?.platformPickUpAddress?.state
      );
      setPpCityOptions(
        singleBusinessContactInfo?.data?.platformPickUpAddress?.city
      );
      setSrCountryOptions(
        singleBusinessContactInfo?.data?.returnAddressInfos?.country
      );
      setSrStateOptions(
        singleBusinessContactInfo?.data?.returnAddressInfos?.state
      );
      setSrCityOptions(
        singleBusinessContactInfo?.data?.returnAddressInfos?.city
      );
      setPrCountryOptions(
        singleBusinessContactInfo?.data?.platformReturnAddress?.country
      );
      setPrStateOptions(
        singleBusinessContactInfo?.data?.platformReturnAddress?.state
      );
      setPrCityOptions(
        singleBusinessContactInfo?.data?.platformReturnAddress?.city
      );
      // setCountryOptions({
      //   loading: false,
      //   list: [
      //     {
      //       value: singleBusinessContactInfo?.data?.country,
      //       label: singleBusinessContactInfo?.data?.country,
      //     },
      //     ...(countryOptions.list?.filter(
      //       (item) => item.value !== singleBusinessContactInfo?.data?.country
      //     ) || []),
      //   ],
      // });
    }
  }, [form, singleBusinessContactInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    // setTinUrl(singleBusinessContactInfo?.data?.merchantBusinessInfo?.attachTinDocuments);
  };

  return (
    <>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Business and contract address"
        subTitle="Business and contract address"
        extra={<></>}
      >
        {/* <div className="content-body bg-white rounded-2xl p-4 shadow"> */}
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo,
            businessAddress:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.address || "",
            postalCode:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.postalCode || "",
            businessNearestLandMark:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.nearestLandMark || "",
            businessContactPerson:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonName || "",
            businessContractNumber:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonNumber || "",
            businessContractEmail:
              singleBusinessContactInfo?.data?.merchantBusinessAndContactInfo
                ?.contactPersonEmail || "",
            //sellerWarehouseAddress
            swhAddress:
              singleBusinessContactInfo?.data?.warehouseAddressInfos?.address ||
              "",
            swhPostalCode:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.postalCode || "",
            swhNearestLandMark:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.nearestLandMark || "",
            swhContactPerson:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonName || "",
            swhContractNumber:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonNumber || "",
            swhContactEmail:
              singleBusinessContactInfo?.data?.warehouseAddressInfos
                ?.contactPersonEmail || "",
            //platformWarehouseAddress
            pwAddress:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.address || "",
            pwPostalCode:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.postalCode || "",
            pwNearestLandMark:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.nearestLandMark || "",
            pwContactPerson:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonName || "",
            pwContractNumber:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonNumber || "",
            pwContactEmail:
              singleBusinessContactInfo?.data?.platformWarehouseAddress
                ?.contactPersonEmail || "",
            //sellerPickupAddress
            spAddress:
              singleBusinessContactInfo?.data?.pickupAddressInfos?.address ||
              "",
            spPostalCode:
              singleBusinessContactInfo?.data?.pickupAddressInfos?.postalCode ||
              "",
            spNearestLandMark:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.nearestLandMark || "",
            spContactPerson:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonName || "",
            spContractNumber:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonNumber || "",
            spContactEmail:
              singleBusinessContactInfo?.data?.pickupAddressInfos
                ?.contactPersonEmail || "",
            //platformPickupAddress
            ppAddress:
              singleBusinessContactInfo?.data?.platformPickUpAddress?.address ||
              "",
            ppPostalCode:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.postalCode || "",
            ppNearestLandMark:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.nearestLandMark || "",
            ppContactPerson:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonName || "",
            ppContactNumber:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonNumber || "",
            ppContactEmail:
              singleBusinessContactInfo?.data?.platformPickUpAddress
                ?.contactPersonEmail || "",
            //sellerReturnAddess
            srAddress:
              singleBusinessContactInfo?.data?.returnAddressInfos?.address ||
              "",
            srPostalCode:
              singleBusinessContactInfo?.data?.returnAddressInfos?.postalCode ||
              "",
            srNearestLandMark:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.nearestLandMark || "",
            srContactPerson:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonName || "",
            srContractNumber:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonNumber || "",
            srContactEmail:
              singleBusinessContactInfo?.data?.returnAddressInfos
                ?.contactPersonEmail || "",
            //platformReturnAddress
            prAddress:
              singleBusinessContactInfo?.data?.platformReturnAddress?.address ||
              "",
            prPostalCode:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.postalCode || "",
            prNearestLandMark:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.nearestLandMark || "",
            prContactPerson:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonName || "",
            prContractNumber:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonNumber || "",
            prContactEmail:
              singleBusinessContactInfo?.data?.platformReturnAddress
                ?.contactPersonEmail || "",
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
            <div className="flow-root">
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Business Info"
                    name="businessAddress"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setBusinessAddress}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="">
                <div className="col-span-2 mb-8">
                  <Form.Item
                    hasFeedback
                    label="Business name"
                    rules={[
                      {
                        required: false,
                        message: "Business name is Required!",
                      },
                    ]}
                    initialValue={shopInfo?.name}
                    name="businessName"
                  >
                    <Input
                      id="businessName"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Address"
                    rules={[
                      {
                        required: false,
                        message: "Address is Required!",
                      },
                    ]}
                    name="businessAddress"
                  >
                    <Input
                      id="businessAddress"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`Country/Region`}
                    rules={[
                      {
                        required: false,
                        message: "Region is required!",
                      },
                    ]}
                    name="businessCountry"
                    initialValue={countryOptions}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={(e) => setBusinessCountryId(e)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="businessState"
                    initialValue={stateOptions}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={(val) => setBusinessStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "state");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={stateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="businessCity"
                    initialValue={cityOptions}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      onChange={(val) => setBusinessCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "city");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={cityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label={`Postal/Gip Code`}
                    rules={[
                      {
                        required: false,
                        message: "Postal Code is required!",
                      },
                    ]}
                    name="postalCode"
                  >
                    <Input
                      id="postalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Nearest Landmark"
                    rules={[
                      {
                        required: false,
                        message: "Nearest LandMark is Required!",
                      },
                    ]}
                    name="businessNearestLandMark"
                  >
                    <Input
                      id="businessNearestLandMark"
                      type="text"
                      placeholder="Enter Nearest LandMark"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Contact Person Name"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Name is Required!",
                      },
                    ]}
                    name="businessContactPerson"
                  >
                    <Input
                      id="businessContactPerson"
                      type="text"
                      placeholder="Enter Contact Person"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Contact Person Number"
                    rules={[
                      {
                        required: false,
                        message: "Contact Person Number is Required!",
                      },
                    ]}
                    name="businessContractNumber"
                  >
                    <Input
                      id="businessContractNumber"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Email is Required!",
                      },
                    ]}
                    name="businessContractEmail"
                  >
                    <Input
                      id="businessContractEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          {sellerWarehouseAddress === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Seller Warehouse Address"
                        name="swAddress"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setSellerWarehouseAddress}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="swhAddress"
                      >
                        <Input
                          id="swhAddress"
                          type="text"
                          placeholder="Enter Address"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Status is required!",
                          },
                        ]}
                        name="SWHCountry"
                        initialValue={swhCountryOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Country"
                          optionFilterProp="children"
                          onChange={(val) => setSwhCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={swhCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="swhState"
                        initialValue={swhStateOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setSwhStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "swhState");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={swhStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="swhCity"
                        initialValue={swhCityOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setSwhCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "swhCity");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={swhCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="swhPostalCode"
                      >
                        <Input
                          id="swhPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="swhNearestLandMark"
                      >
                        <Input
                          id="swhNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="swhContactPerson"
                      >
                        <Input
                          id="swhContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="swhContractNumber"
                      >
                        <Input
                          id="swhContractNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="swhContactEmail"
                      >
                        <Input
                          id="swhContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {platformWarehouseAddress === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Platform Warehouse Address"
                        name="Platform"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setPlatformWarehouseAddress}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="pwAddress"
                      >
                        <Input
                          id="pwAddress"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Country is required!",
                          },
                        ]}
                        name="pwCountry"
                        initialValue={pwCountryOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Country"
                          optionFilterProp="children"
                          onChange={(val) => setPwCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={pwCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="pwState"
                        initialValue={pwStateOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setPwStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "pwState");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={pwStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="pwCity"
                        initialValue={pwCityOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setPwCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "pwCity");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={pwCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="pwPostalCode"
                      >
                        <Input
                          id="pwPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="pwNearestLandMark"
                      >
                        <Input
                          id="pwNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="pwContactPerson"
                      >
                        <Input
                          id="pwContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="pwContractNumber"
                      >
                        <Input
                          id="pwContractNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="pwContactEmail"
                      >
                        <Input
                          id="pwContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {sellerPickupAddress === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Seller Pickup Address"
                        name="isFreeDelivery"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setSellerPickupAddress}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="spAddress"
                      >
                        <Input
                          id="spAddress"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Status is required!",
                          },
                        ]}
                        name="spCountry"
                        initialValue={spCountryOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Merchant"
                          optionFilterProp="children"
                          onChange={(val) => setSpCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={spCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="spState"
                        initialValue={spStateOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setSpStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "state");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={spStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="spCity"
                        initialValue={spCityOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setSpCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "spCity");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={spCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="spPostalCode"
                      >
                        <Input
                          id="spPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="spNearestLandMark"
                      >
                        <Input
                          id="spNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="spContactPerson"
                      >
                        <Input
                          id="spContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="spContractNumber"
                      >
                        <Input
                          id="spContractNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="spContactEmail"
                      >
                        <Input
                          id="spContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {platformPickupAddress === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Platform Pickup Address"
                        name="platFromPickup"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setPlatformPickupAddress}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="ppAddress"
                      >
                        <Input
                          id="ppAddress"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Country is required!",
                          },
                        ]}
                        name="ppCountryId"
                        initialValue={ppCountryOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Country"
                          optionFilterProp="children"
                          onChange={(val) => setPpCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={ppCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="ppState"
                        initialValue={ppStateOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setPpStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "ppState");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={ppStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="ppCity"
                        initialValue={ppCityOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setPpCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "city");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={ppCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="ppPostalCode"
                      >
                        <Input
                          id="ppPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="ppNearestLandMark"
                      >
                        <Input
                          id="ppNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="ppContactPerson"
                      >
                        <Input
                          id="ppContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="ppContactNumber"
                      >
                        <Input
                          id="ppContactNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="ppContactEmail"
                      >
                        <Input
                          id="ppContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {sellerReturnAddess === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Seller Return Address"
                        name="sellerReturn"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setSellerReturnAddess}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="srAddress"
                      >
                        <Input
                          id="srAddress"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Status is required!",
                          },
                        ]}
                        name="srCountry"
                        initialValue={srCountryOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Country"
                          optionFilterProp="children"
                          onChange={(val) => setSrCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={srCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="srState"
                        initialValue={srStateOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setSrStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "srState");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={srStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="srCity"
                        initialValue={srCityOptions}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setSrCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "srCity");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={srCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="srPostalCode"
                      >
                        <Input
                          id="srPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="srNearestLandMark"
                      >
                        <Input
                          id="srNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="srContactPerson"
                      >
                        <Input
                          id="srContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="srContractNumber"
                      >
                        <Input
                          id="srContractNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="srContactEmail"
                      >
                        <Input
                          id="srContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {platformReturnAddress === false && (
            <>
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
                <div className="flow-root">
                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Platform Return Address"
                        name="prAddress"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={false}
                          onChange={setPlatformReturnAddress}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Business name"
                        rules={[
                          {
                            required: false,
                            message: "Business name is Required!",
                          },
                        ]}
                        initialValue={shopInfo?.name}
                        name="name"
                      >
                        <Input id="name" type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Address"
                        rules={[
                          {
                            required: false,
                            message: "Address is Required!",
                          },
                        ]}
                        name="prAddress"
                      >
                        <Input
                          id="prAddress"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Country/Region`}
                        rules={[
                          {
                            required: false,
                            message: "Status is required!",
                          },
                        ]}
                        name="prCountry"
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select Merchant"
                          optionFilterProp="children"
                          onChange={(val) => setPrCountryId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "country");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={prCountryOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label={`State/Province`}
                        rules={[
                          {
                            required: false,
                            message: "State is required!",
                          },
                        ]}
                        name="prState"
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          onChange={(val) => setPrStateId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "state");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={prStateOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`City`}
                        rules={[
                          {
                            required: false,
                            message: "City is required!",
                          },
                        ]}
                        name="prCity"
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          onChange={(val) => setPrCityId(val)}
                          onSearch={(val) => {
                            handleSearch?.(val, "prCity");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={prCityOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label={`Postal/Gip Code`}
                        rules={[
                          {
                            required: false,
                            message: "Postal Code is required!",
                          },
                        ]}
                        name="prPostalCode"
                      >
                        <Input
                          id="prPostalCode"
                          type="text"
                          placeholder="Enter Postal Code"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Nearest Landmark"
                        rules={[
                          {
                            required: false,
                            message: "Nearest LandMark is Required!",
                          },
                        ]}
                        name="prNearestLandMark"
                      >
                        <Input
                          id="prNearestLandMark"
                          type="text"
                          placeholder="Enter Nearest LandMark"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-x-6">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Name"
                        rules={[
                          {
                            required: false,
                            message: "Contact Person Name is Required!",
                          },
                        ]}
                        name="prContactPerson"
                      >
                        <Input
                          id="prContactPerson"
                          type="text"
                          placeholder="Enter Contact Person"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Contact Person Number"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="prContractNumber"
                      >
                        <Input
                          id="prContractNumber"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Email is Required!",
                          },
                        ]}
                        name="prContactEmail"
                      >
                        <Input
                          id="prContactEmail"
                          type="text"
                          placeholder="Enter Contact Email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
            <div className="flow-root">
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Divider orientation="left">
                    Seller Warehouse Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setSellerWarehouseAddress}
                    />
                  </Divider>
                </div>
                <div>
                  <Divider orientation="left">
                    Platform Warehouse Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setPlatformWarehouseAddress}
                    />
                  </Divider>
                </div>
                <div>
                  <Divider orientation="left">
                    Seller Pickup Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setSellerPickupAddress}
                    />
                  </Divider>
                </div>
                <div>
                  <Divider orientation="left">
                    Platform Pickup Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setPlatformPickupAddress}
                    />
                  </Divider>
                </div>
                <div>
                  <Divider orientation="left">
                    Seller Return Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setSellerReturnAddess}
                    />
                  </Divider>
                </div>
                <div>
                  <Divider orientation="left">
                    Platform Return Address
                    <Tooltip
                      placement="bottom"
                      title={"Same as business Address"}
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={true}
                      onChange={setPlatformReturnAddress}
                    />
                  </Divider>
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            <Button
              //type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
        {/* </div> */}
      </ShopLayout>
    </>
  );
};

export default BusinessContractAddress;
