import { Button, Form, Input, Select } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";
import { responseNotification } from "../../utils/notify";
import { debounce } from "lodash";
import axios from "axios";

function ForgotPassword({ refetch }: { refetch?: any }) {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [sendOtp, setSendOtp] = useState(true);
  const [form] = useForm();
  const [countryId, setCountryId] = useState<any>("BD");
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [singleCountry, setSingleCountry] = useState<any>({
    loading: false,
    data: null,
  });

  const onSubmit = async (data: FieldValues) => {
    const newPassword = data?.confirm;
    const otp = data?.otp;
    if (sendOtp) {
      const res = await fetch(`${process.env.REACT_APP_AUTH_API}/otp/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          countryCode: countryId,
          mobileNumber: `+88${mobileNumber?.replace("+88", "")}`,
        }),
      });

      const response1 = await res.json();

      if (response1?.statusCode === 200) {
        setSendOtp(false);
        responseNotification(
          response1?.message?.replace("Operation", "Otp sent"),
          "success"
        );
      }
    } else {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_API}/merchant/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobileNumber: `+88${mobileNumber?.replace("+88", "")}`,
            newPassword: newPassword,
            otp: otp,
          }),
        }
      );

      const response2 = await res.json();
      if (response2?.statusCode === 200) {
        setLoading(false);
        refetch();
        responseNotification(
          response2?.message?.replace("Operation", "Password Change"),
          "success"
        );
        form.resetFields();
      } else {
        responseNotification(
          response2?.message?.replace("Operation", "Password Change"),
          "warning"
        );
      }
    }
  };

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``)
    );
    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.iso2,
          label: country?.name,
        };
      }),
    });
  }, []);
  const fetchCountryDetails = useCallback((countryId: any) => {
    if (countryId) {
      try {
        setSingleCountry({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/country-state/country-details?countryCode=${countryId}`
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCountry({
                loading: false,
                data: data?.data?.country,
              });
            } else {
              setSingleCountry({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCountry({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleCountry({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchCountryDetails(countryId);
  }, [fetchCountryDetails, countryId]);
  // password show and hide
  const toggleShowPassword = () => {
    const password = document.querySelector("#fPassword") as any;
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    password.classList.toggle("anticon-eye-invisible");
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "country") getCountryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions]);
  useEffect(() => {
    getCountryOptions();
  }, [getCountryOptions]);

  return (
    <Form onFinish={onSubmit} layout="vertical" form={form} size="large">
      <Input type="hidden" name="remember" defaultValue="true" />
      {sendOtp ? (
        <>
          <Form.Item
            hasFeedback
            label={`Country/Region`}
            rules={[
              {
                required: false,
                message: "Region is required!",
              },
            ]}
            name="businessCountry"
            initialValue={countryId}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Country"
              optionFilterProp="children"
              onChange={(val) => setCountryId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "country");
              }}
              filterOption={() => {
                return true;
              }}
              options={countryOptions?.list}
            ></Select>
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            initialValue={"01"}
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
          >
            <Input
              className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              // prefix={<MobileOutlined />}
              placeholder="01XXXXXXXXX"
              maxLength={11}
              type="contact"
              addonBefore={singleCountry?.data?.phone_code || "+88"}
              onChange={(e: any) => setMobileNumber(e.target.value)}
            />
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item label="OTP" name="otp" id="otp">
            <Input
              className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              placeholder="Enter your OTP"
              id="otp"
              autoComplete={"off"}
            />
          </Form.Item>
          <Form.Item label="Password" name="fPassword">
            <Input
              className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              // prefix={<UnlockOutlined />}
              suffix={
                <EyeInvisibleOutlined id="ddd" onClick={toggleShowPassword} />
              }
              placeholder="Enter your password"
              type="password"
              maxLength={11}
              id="fPassword"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("fPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password maxLength={11} placeholder="Re-Type Password" />
          </Form.Item>
        </>
      )}
      <div className="mt-6">
        <Button
          htmlType="submit"
          //   size="large"
          //   block
          loading={loading}
          disabled={loading}
          className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md"
          style={{ background: "#CD113B" }}
        >
          Continue
        </Button>
      </div>
    </Form>
  );
}
export default ForgotPassword;
