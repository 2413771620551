
import React, { useCallback,useEffect,ReactElement, useState } from "react";

import { Form, Input, Select } from "antd";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import UserHeader from "../MerchantProfile/UserHeader";
import MemberShipList from "../MerchantProfile/MemberShipList";
import { ImageUploader, SubmitResetBtn } from "../common";
import { responseNotification } from "../../utils/notify";

const PersonalDetails = (): ReactElement => {
    const { token } = useSelector((state) => (state as any)?.authReducer);
    const [identificationUrl, setIdentificationUrl] = useState<string>();
    const navigate = useNavigate();
    const [profilePicUrl, setProfilePicUrl] = useState<string>();
    const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
      loading: false,
      data: null,
    });
    const [profilePicture, setProfilePicture] = useState<string>();
    console.log('profilePicture ::',profilePicture);
  
    const [identificationUrl2, setIdentificationUrl2] = useState<string>();
    const [identificationDocumentUrl, setIdentificationDocumentUrl] = useState<string>();
    console.log('identificationDocumentUrl::',identificationDocumentUrl);
    const [startUploadProfilePic, setStartUploadProfilePic] = useState<
      "Initiated" | "Uploading" | "Uploaded"
    >();
  
  
    const [startIdentificationUpload2, setStartIdentificationUpload2] = useState<
      "Initiated" | "Uploading" | "Uploaded"
    >();
    const [startUpload3, setStartUpload3] = useState<
      "Initiated" | "Uploading" | "Uploaded"
    >();
  
    const [uploadProfilePicture, setUploadProfilePicture] = useState<
      "Initiated" | "Uploading" | "Uploaded"
    >();
    const [startUploadFront, setStartUploadFront] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
    const [loading, setLoading] = useState(false);
    const [documentType, setDocumentType] = useState("");
    const [form] = Form.useForm();
  
    const fetchMerchantDetails = useCallback(() => {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(`${process.env.REACT_APP_RIDER_API}/details`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
              setIdentificationUrl2(
                data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl2
              );
              setProfilePicture(
                data?.data?.merchant?.merchantPersonalDetail?.profilePicture
              );
              setIdentificationDocumentUrl(
                data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl
              );
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });
  
            console.log(err);
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });
  
        console.log(error, "error");
      }
    }, [token]);
  
    useEffect(() => {
      fetchMerchantDetails();
    }, [fetchMerchantDetails, token]);
    useEffect(() => {
      if (singleMerchantInfo.data) {
        form.resetFields(Object.keys(singleMerchantInfo.data as any));
        setIdentificationUrl(
          singleMerchantInfo?.data?.merchantBusinessInfo?.attachTinDocuments
        );
      }
    }, [form, singleMerchantInfo.data]);
  
    function handleChange(value) {
      setDocumentType(value);
    }
    useEffect(() => {
      if (uploadProfilePicture === "Uploaded") {
        form?.submit();
      }
    }, [form, uploadProfilePicture]);
  
    const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        name: data.name,
        dateOfBirth: data.dateOfBirth,
        email: data.email,
        gender: data.gender,
        identificationDocumentType: data.identificationDocumentType,
        identificationDocumentUrl: identificationDocumentUrl || "",
        identificationDocumentUrl2: identificationUrl2 || "",
        profilePicture: profilePicture || "",
      };
  
      await fetch(`${process.env.REACT_APP_RIDER_API}/merchant/personal-info`, {
        method: "PUT",
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
  
          if (res.statusCode === 200) {
            responseNotification(data?.message, "success");
            form.resetFields(); 
            //window.location.reload();
            navigate(-1);
            // navigate("/account/my-profile"); 
          } else if (res.status === 500) { 
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    };
  
    const resetData = () => {
      form?.resetFields();
    };
  
    useEffect(() => {
      if (singleMerchantInfo?.data) {
        form.resetFields();
        setProfilePicture(
          singleMerchantInfo?.data?.merchantPersonalDetail?.profilePicture
        );
        setIdentificationUrl2(
          singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl2
        );
        setIdentificationDocumentUrl(
          singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl
        );
      }
    }, [form, singleMerchantInfo?.data]);

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 merchantMainDiv">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={`content-body-withSearch` 
              }
            >
  <div className="content-body rounded-2xl bg-white p-4 min-h-full">
      <Form
        name="control-hooks"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        initialValues={{
          ...singleMerchantInfo?.data?.merchantPersonalDetail,
          name: singleMerchantInfo?.data?.merchantPersonalDetail?.name || "",
          email: singleMerchantInfo?.data?.merchantPersonalDetail?.email || "",
        }}
      >
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name="name"
            hasFeedback
            label="Owner Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
          >
            <Input type="text" id="name" placeholder="Enter name" />
          </Form.Item>

          <Form.Item
            name="email"
            hasFeedback
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Email is Required!",
              },
            ]}
          >
            <Input type="text" id="email" placeholder="Enter email" />
          </Form.Item>

          <Form.Item
            name="gender"
            hasFeedback
            label="Select Gender"
            rules={[
              {
                required: true,
                message: "Gender is Required!",
              },
            ]}
          >
            <Select
              placeholder="Select Gender"
              optionFilterProp="children"
              options={[
                { label: "MALE", value: "MALE" },
                { label: "FEMALE", value: "FEMALE" },
              ]}
            ></Select>
          </Form.Item>

          {/* <Form.Item
            hasFeedback
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Date of birth is Required!",
              },
            ]}
          >
            <DatePicker
              id="dateOfBirth"
              placeholder="Enter Date of Birth"
              className="w-full"
            />
          </Form.Item> */}

          <Form.Item
            name="identificationDocumentType"
            hasFeedback
            label="Document Type"
            rules={[
              {
                required: true,
                message: "Document Type is Required!",
              },
            ]}
          >
            <Select
              defaultValue="Select"
              onChange={handleChange}
              options={[
                {
                  label: "NID",
                  value: "NID",
                },
                {
                  label: "PASSPORT",
                  value: "PASSPORT",
                },
                {
                  label: "BIRTH CERTIFICATE",
                  value: "BIRTH_CERTIFICATE",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="basis-0 pr-2">
            <span className="text-md">Front Image</span>
            <ImageUploader
              imgUrl={identificationDocumentUrl}
              startUpload={startUploadFront}
              setStartUpload={(val) => setStartUploadFront(val)}
              setGeneratedImgUrl={(url) => setIdentificationDocumentUrl(url)}
            />
          </div>

     
            <div className="basis-0">
              <span className="text-md">Back Image</span>
              <ImageUploader
                imgUrl={identificationUrl2}
                startUpload={startIdentificationUpload2}
                setStartUpload={(val) => setStartIdentificationUpload2(val)}
                setGeneratedImgUrl={(url) => setIdentificationUrl2(url)}
              />
            </div>
         

          <div className="basis-0">
            <span className="text-md">Profile Picture</span>
            <ImageUploader
              imgUrl={profilePicture || ""}
              startUpload={uploadProfilePicture}
              setStartUpload={(val: any) => setUploadProfilePicture(val)}
              setGeneratedImgUrl={(url: any) => setProfilePicture(url)}
            />
          </div>
        </div>

        <div className="mt-2">
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartIdentificationUpload2("Uploading");
              setStartUploadFront("Uploading");
              setUploadProfilePicture("Uploading");
            }}
            onClickReset={() => {
              form?.resetFields();
              setStartIdentificationUpload2(undefined);
              setStartUploadFront(undefined);
              setUploadProfilePicture(undefined);
            }}
            disabled={loading || startIdentificationUpload2 === "Uploading" || uploadProfilePicture === "Uploading" || startUploadFront === "Uploading"}
            loading={loading || startIdentificationUpload2 === "Uploading" || uploadProfilePicture === "Uploading" || startUploadFront === "Uploading"}
          />
        </div>
      </Form>
    </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
