import { EditOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Radio,
  Rate,
  Row,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { responseNotification } from "../../utils/notify";
interface EmptyProps {
  height?: string;
  data?: string;
}

const MemberShipList = () => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );

  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var tawkTo=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
tawkTo.async=true;
tawkTo.src='https://embed.tawk.to/66a8859632dca6db2cb7511d/1icasp7ig';
tawkTo.charset='UTF-8';
tawkTo.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(tawkTo,s0);
})();
const [getBankType, setGetBankType] = useState("BANKING");
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const shopId = merchantShopCheck?.[0].id;
  const [businessContractCheck, setBusinessContractCheck] = useState<any>();
  const [personalDetailsCheck, setPersonalDetailsCheck] = useState<any>();
  const [addBankInfoCheck, setAddBankInfoCheck] = useState<any>();
  const [liveChatCheck, setLiveChatCheck] = useState<any>();


  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);
  const getBusinessContractCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBusinessContractCheck(res?.data?.shop);
  }, [shopId]);
  const fetchMerchantDetails = useCallback(() => {
    try {
      setPersonalDetailsCheck({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setPersonalDetailsCheck({
              loading: false,
              data: data?.data?.merchant?.merchantPersonalDetail,
            });
          } else {
            setPersonalDetailsCheck({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setPersonalDetailsCheck({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setPersonalDetailsCheck({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [token]);
  const fetchMerchantBankDetails = useCallback(() => {
    try {
      setAddBankInfoCheck({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/getAllBank?shopId=${shopId}&bankingType=${getBankType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAddBankInfoCheck({
              loading: false,
              data: data?.data?.shopBanks,
            });
          } else {
            setAddBankInfoCheck({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setAddBankInfoCheck({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setAddBankInfoCheck({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [getBankType]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, getBankType]);

  useEffect(() => {
    getMerchantShopCheck();
    getBusinessContractCheck();
    fetchMerchantDetails();
  }, [getMerchantShopCheck,getBusinessContractCheck,fetchMerchantDetails]);

  return (
    <>
      <div className={styles.memberShipList}>
        <div className="flex w-full rounded-2xl h-52" style={{ backgroundColor: "#f4edce" }}>
          <div className="w-[20%] h-full rounded-2xl grid grid-cols-2" style={{ backgroundColor: "#e9a92b" }}>
            <div className="col-span-2 mb-1 text-right m-2">
            
              <Link
                title="Edit Product"
                to="/personal-details"
                // target="_blank"
                className=""
              >
                <EditOutlined />
              </Link>
            </div>
            <div className="col-span-2 mb-1 text-center">
              <Avatar size={75} src={""} className="border" />
              <h2 className="text-xl font-bold">Merchant Name</h2>
              <h3 className="text-xl font-bold">JM 5421547</h3>
              <Rate value={1} className="mt-1" disabled />
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                {5}
              </span>
            </div>
          </div>
          <div className="flex h-full w-[70%]" style={{ backgroundColor: "#f4edce" }}>
            <div className="p-4 mb-10  grid grid-cols-2 gap-x-2 w-full text-center">
              <div className="col-span-2">
                <div className="my-2 w-full float-left">
                  <div
                    className="flex flex-row justify-between rounded-lg"
                    style={{ backgroundColor: "#047e94" }}
                  >
                    <div className="text-white pl-2 py-3 text-justify">
                      <h1>jachai.com Membership</h1>
                      <h2>
                        Explore US Tk 10 of wih a new seller and more benefits
                      </h2>
                    </div>
                    <div className="mx-5 mt-3.5 cursor-pointer">
                      <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-200 dark:text-blue-800 ml-3">
                      
                      <Link to="/membership">
                        Explore
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="my-2 w-full float-left text-justify">
                  <p>
                    <span className="font-bold">Verification in progress:</span>{" "}
                    our team is review your Documents. Expect a response within
                    48 hours. Thank you for your patience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex h-full w-[10%]">
            {/* QR code start  */}
           
          <div>
            <Avatar
            src={
              "../../images/qr.png"
            }
            size={45}
            shape="square"
            className="absolute bottom-4 right-6 h-16 w-16"
          />
         
          </div>
            {/* QR code end */}
          </div>
        </div>


        {/* <div className="flex">
          <Row className="w-full">
            <Col className="w-full text-right mr-10">
              <Link to="/">
                <Button type="link" className={styles.skipStyle}>
                  SKIP
                </Button>
              </Link>
            </Col>
          </Row>
        </div> */}
        <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  {merchantShopCheck?.length > 0 ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business/create">Create Business</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/chats">Live Chat</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}

            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                {businessContractCheck?.merchantBusinessAndContactInfo?.contactPersonName ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business-contract-address">Business & Contact Address</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> 
        <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                {businessContractCheck?.merchantBusinessInfo?.length > 0 ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business-info">Business Info</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                {personalDetailsCheck?.length > 0 ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/personal-details">Personal Details</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="flex">
          <Row className="p-4 w-full">
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Verify Bank</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Add Product</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>
        <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                {addBankInfoCheck?.length > 0 ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/bank-info">Add Bank</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Facebook Page</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Refer and get Discounts</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>
        <div className="flex">
          <Row className="p-4 w-full">
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Switch to Customer</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}
       
          </Row>
        </div>
        <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Facebook Group</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="javascript:void(Tawk_API.toggle())">Live Chat</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};




export default MemberShipList;
