import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import MerchantBusinessContractAddress from "../../components/MerchantProfile/BusinessContractAddress";

const MerchantBusinessContractAddressPage = () => {
  return (
    <MerchantLayout>
      <MerchantBusinessContractAddress />
    </MerchantLayout>
  ); 
};

export default MerchantBusinessContractAddressPage;
