import { EditOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
interface EmptyProps {
  height?: string;
  data?: string;
}
import { debounce } from "lodash";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { useNavigate } from "react-router-dom";
import { LOGIN_SUCCESS } from "../../redux/auth/authType";

const BusinessCreate = () => {
  const { type, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  ); 
  console.log('merchantInfo <=====>',merchantInfo);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [operationType, setOperationType] = useState<any>("INDIVIDUAL");
  const [businessType, setBusinessType] = useState<any>();
  const [serviceId, setServiceId] = useState<any>("");
  const [companyId, setCompanyId] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [serviceOptions, setServiceOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [companyOptions, setCompanyOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const getBusinessTypeOptions = useCallback(async (key?: string) => {
    setBusinessTypeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/service-type-list?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBusinessTypeOptions({
      loading: false,
      list: res?.data?.businessTypes?.map((business: any) => {
        return {
          value: business?.id,
          label: business?.name,
        };
      }),
    });
  }, []);

  const businessServiceOption = useCallback(
    async (key?: string) => {
      setServiceOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/details-business-type?id=${businessType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setServiceOptions({
        loading: false,
        list: res?.data?.businessType?.serviceTypes?.map((service) => {
          return {
            value: service?.value,
            label: service?.name,
          };
        }),
      });
    },
    [businessType]
  );

  const companyOption = useCallback(async (key?: string) => {
    setCompanyOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/company-list?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCompanyOptions({
      loading: false,
      list: res?.data?.companies?.map((company) => {
        return {
          value: company?.id,
          label: company?.name,
        };
      }),
    });
  }, []);

  useEffect(() => {
    companyOption();
    businessServiceOption();
    getBusinessTypeOptions(); 
  }, [companyOption, businessServiceOption, getBusinessTypeOptions]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      businessTypeId: businessType,
      type: serviceId,
      businessOperationType: operationType,
      companyId: companyId,
      companyName: data.companyName,
      name: data.name,
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/create-shop-by-sm`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...readyData, isActive: false }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          // store business info
          dispatch({
            type: LOGIN_SUCCESS, 
            payload: {
              ...res,
              token: token,
              shopInfo: res?.shop,
              shopId: res?.shop?.id,
              iat: new Date(),
              type: res?.shop?.type || "JC_COMMERCE",
            },
          }); 
          // store business info end
          responseNotification("Business Create Successfully", "success");
          form.resetFields();
          navigate("/business/personal-details");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "businessType") getBusinessTypeOptions(value);
          if (field === "service") businessServiceOption(value);
          if (field === "company") companyOption(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getBusinessTypeOptions, businessServiceOption, companyOption, type]);

  return (
    <>
      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <div className="">
            <Form.Item
              hasFeedback
              label="Business Type"
              rules={[
                {
                  required: true,
                  message: "Business Type is required!",
                },
              ]}
              name="businessTypeId"
            >
              <Select
                showSearch
                placeholder="Select Business Type"
                optionFilterProp="children"
                onSearch={(val) => {
                  handleSearch(val, "businessType");
                }}
                onChange={(val) => setBusinessType(val)}
                filterOption={() => {
                  return true;
                }}
                options={businessTypeOptions?.list}
              ></Select>
            </Form.Item>
          </div>
          <div className="">
            <Form.Item
              hasFeedback
              label="Service Type"
              rules={[
                {
                  required: true,
                  message: "Service Type is required!",
                },
              ]}
              name="shopId"
            >
              <Select
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onSearch={(val) => {
                  handleSearch(val, "service");
                }}
                onChange={(val) => setServiceId(val)}
                filterOption={() => {
                  return true;
                }}
                options={serviceOptions?.list}
              ></Select>
            </Form.Item>
          </div>
          <div className="">
            <Form.Item
              hasFeedback
              label="Business Operation Type"
              rules={[
                {
                  required: true,
                  message: "Business Operation Type is required!",
                },
              ]}
              name="businessOperationType"
            >
              <Select
                onChange={(val) => setOperationType(val)}
                options={[
                  {
                    value: "INDIVIDUAL",
                    label: "None, I am an Indusial (As a Person)",
                  },
                  {
                    value: "COMPANY",
                    label: "As a Company",
                  },
                ]}
                placeholder="Enter Operation Type"
              />
            </Form.Item>
          </div>
          {operationType === "COMPANY" && (
            <>
              {companyOptions?.length > 0 && (
                <>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Company Name"
                      rules={[
                        {
                          required: false,
                          message: "Company name is required!",
                        },
                      ]}
                      name="companyId"
                    >
                      <Select
                        showSearch
                        placeholder="Select Company name"
                        optionFilterProp="children"
                        onSearch={(val) => {
                          handleSearch(val, "company");
                        }}
                        onChange={(val) => setCompanyId(val)}
                        filterOption={() => {
                          return true;
                        }}
                        options={companyOptions?.list}
                      ></Select>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Checkbox.Group>
                      <Row>
                        <Checkbox
                          value="newComapny"
                          checked={isChecked}
                          onChange={handleOnChange}
                        >
                          Add New Company
                        </Checkbox>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  {isChecked && (
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="New Company Name"
                        name="companyName"
                      >
                        <Input
                          id="companyName"
                          type="text"
                          placeholder="Enter Company Name"
                        />
                      </Form.Item>
                    </div>
                  )}
                </>
              )}

              <div className="">
                <Form.Item
                  hasFeedback
                  label="New Company Name"
                  name="companyName"
                >
                  <Input
                    id="companyName"
                    type="text"
                    placeholder="Enter Company Name"
                  />
                </Form.Item>
              </div>
            </>
          )}
          <div className="">
            <Form.Item hasFeedback label="Business Name" name="name">
              <Input id="name" type="text" placeholder="Enter Business name" />
            </Form.Item>
          </div>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            <Button
              //type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BusinessCreate;
