import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import GetShopBanksList from "../../Profile/Bankdetails/index";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import BreadCrumb from "../../Layouts/Breadcrumb";
import styles from "../../../styles/tailwind/List.module.css";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Pagination,
  Popconfirm,
  Switch,
} from "antd";
import { AddBtn, Loader } from "../../common";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { getPage, getParamValue } from "../../../utils";
import moment from "moment";
import SingleUsers from "./Details";
import AddUsers from "./AddUsers";

const ShopUsersList = (): ReactElement => {
  const { type, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const loc = useLocation();
  const shopId = (route as any)?.shopId;
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedBanner, setSelectedBanner] = useState(undefined);
  const [selectedBannerForEdit, setSelectedBannerForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [userData, setUserData] = useState<any>({
    loading: false,
    data: null,
  });
  const getUsers = useCallback(async () => {
    setUserData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(
        `${encodedUri}/merchant/user?shopId=${shopId}` +
          (page
            ? `&page=${
                page == userData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUserData({ loading: false, data: [] });
        console.error("Brand: Error", err);
      });
  }, [
    limit,
    page,
  ]);

  const onDelete = async (id: any, val: any) => {
}; 

const onBannerRemove = async (id: any) => {
};
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const onClose = () => {
    getUsers();
    setVisible(undefined);
    setSelectedBanner(undefined);
    setSelectedBannerForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  return (
    <>
      <BreadCrumb
        title="Banner List"
        subTitle={`${
          userData?.data?.totalElements ||
          userData?.data?.users?.length ||
          0
        } Banner(s)`}
        extra={[
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {userData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Banner</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {userData?.data?.users?.length ? (
                      userData?.data?.users?.map(
                        (banner: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedBanner(banner)}
                          >
                            <td>
                              <Avatar size={40} src={banner?.bannerImage} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {banner?.bannerName}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!banner?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(banner?.id, val);
                                }}
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(banner?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(banner?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(banner?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {banner?.updatedBy?.name ||
                                  banner?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedBannerForEdit(banner)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={banner?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBannerRemove(banner?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(banner?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...userData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Banner"
            : selectedBannerForEdit
            ? "Edit Banner"
            : "Banner Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBanner || selectedBannerForEdit}
        width={450}
      >
        {visible || selectedBannerForEdit ? (
          <AddUsers
            onCloseMethod={onClose}
            visibleData={selectedBannerForEdit}
            getShopId={(route as any)?.shopId}
          />
        ) : (
          <SingleUsers BannerDetails={selectedBanner} />
        )}
      </Drawer>
    </>
  );
};

ShopUsersList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopUsersList;
