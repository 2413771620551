import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BankInfo from "../../components/MerchantProfile/Bank/index";

const BankInfoPage = () => {
  return (
    <MerchantLayout>
      <BankInfo />
    </MerchantLayout>
  ); 
};

export default BankInfoPage;
