//@ts-nocheck
import React, { ReactElement, useState } from "react";

import { Form } from "antd";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import UserHeader from "../MerchantProfile/UserHeader";
import MemberShipList from "../MerchantProfile/MemberShipList";

const MerchantProfile = (): ReactElement => {
  const { type, token, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  ); 
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const [showSearch, setShowSearch] = useState(true);

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 merchantMainDiv">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {/* <UserHeader/> */}
              <MemberShipList/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantProfile;
