import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import ProductList from "../../Product/index";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Pagination,
  Popconfirm,
  Switch,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import moment from "moment";
import Loader from "../../common/Loader";
import AddProduct from "./AddProductUrl";
import SingleBanner from "../Users/Details";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { AddBtn } from "../../common";
import { Copy } from "../../icons";

const ShopProductList = (): ReactElement => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();
  const route = useParams();
  const loc = useLocation();
  const shopId = (route as any)?.shopId;
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedProductUrl, setSelectedProductUrl] = useState(undefined);
  const [selectedProductUrlForEdit, setSelectedProductUrlForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [productUrlData, setProductUrlData] = useState<any>({
    loading: false,
    data: null,
  });

  const getProductURL = useCallback(async () => {
    setProductUrlData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(`${encodedUri}/affiliate-product?shopId=${shopId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProductUrlData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setProductUrlData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [shopId]);

  //

  const onUrlRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/affiliate-product/remove?id=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("URL Remove Successfully", "success");
            getProductURL();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getProductURL();
  }, [getProductURL]);

  const onClose = () => {
    getProductURL();
    setVisible(undefined);
    setSelectedProductUrl(undefined);
    setSelectedProductUrlForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Product Url List"
        subTitle="Product Url List"
        extra={<></>}
      >
        <BreadCrumb
          title="Product List"
          subTitle={`${
            productUrlData?.data?.totalElements ||
            productUrlData?.data?.banners?.length ||
            0
          } Product(s)`}
          extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
        />
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {productUrlData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Product Url</th>
                        <th scope="col">Affiliate Url</th>
                        <th scope="col">Affiliate Code</th>
                        <th scope="col">Shop Name</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {productUrlData?.data?.affiliateProducts?.length ? (
                        productUrlData?.data?.affiliateProducts?.map(
                          (product: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              //onClick={() => setSelectedProductUrl(product)}
                            >
                              <td>
                                
                              <div className="flex border border-accent-2 rounded-md">
                                  <span className="border-r text-center p-1.5 px-4">
                                  {product?.productUrl?.slice(0, 20)}
                                  </span>
                                  <span
                                    className="p-1.5 px-3 cursor-pointer"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        product?.productUrl
                                      );
                                    }}
                                  >
                                    <Copy color={`#838383`} />
                                  </span>
                                  </div>

                                  {/* <Copy color={`#838383`} /> */}
                                  {/* <button onClick={() => {
         navigator.clipboard.writeText(product?.productUrl);}}>
        Copy
       </button> */}
                            
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {product?.affiliateUrl}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {product?.affiliateCode}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {product?.shopName}
                                </span>
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                      product?.updatedAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(product?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(product?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {product?.updatedBy?.name ||
                                    product?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={product?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onUrlRemove(product?.id);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(product?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <Pagination
            {...productUrlData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
        <Drawer
          title={
            visible
              ? "Add Product URL"
              : selectedProductUrlForEdit
              ? "Edit Product URL"
              : "Product URL Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedProductUrl || selectedProductUrlForEdit}
          width={450}
        >
          {visible || selectedProductUrlForEdit ? (
            <AddProduct
              onCloseMethod={onClose}
              visibleData={selectedProductUrlForEdit}
            />
          ) : (
            <SingleBanner BannerDetails={selectedProductUrl} />
          )}
        </Drawer>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopProductList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopProductList;
