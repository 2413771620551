// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/faq.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faq_root__AiKAK {
  display: flex;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 800px
}

.Faq_link__7Pzjg {
  & > svg {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  & > svg {
    transition-duration: 75ms
  }
  & > svg {
    transition-timing-function: linear
  }

  &:hover > svg {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/Landing/Faq/Faq.module.css"],"names":[],"mappings":"AACE;EAAA,aAAiE;EAAjE,mBAAiE;EAAjE,kBAAiE;EAAjE,4DAAiE;EAAjE,iBAAiE;EAAjE,oBAAiE;EACjE,mDAA0C;EAC1C,wBAAwB;EACxB,gCAAgC;EAChC,4BAA4B;EAC5B;AALiE;;AAQnE;EAEI;IAAA;EAAwC;EAAxC;IAAA;EAAwC;EAAxC;IAAA;EAAwC;;EAIxC;IAAA,iBAAgB;IAAhB,iBAAgB;IAAhB;EAAgB;AAEpB","sourcesContent":[".root {\n  @apply bg-gray-50 bg-contain bg-no-repeat py-20 flex items-center;\n  background: url(\"../../../images/faq.svg\");\n  background-size: contain;\n  background-position: left center;\n  background-repeat: no-repeat;\n  min-height: 800px;\n}\n\n.link {\n  & > svg {\n    @apply transform duration-75 ease-linear;\n  }\n\n  &:hover > svg {\n    @apply scale-110;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Faq_root__AiKAK`,
	"link": `Faq_link__7Pzjg`
};
export default ___CSS_LOADER_EXPORT___;
