// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Steps_root__O6Ny9 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    padding-top: 6rem;
    padding-bottom: 6rem
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/Steps/Steps.module.css"],"names":[],"mappings":"AACE;IAAA,kBAAqB;IAArB,4DAAqB;IAArB,iBAAqB;IAArB;AAAqB","sourcesContent":[".root {\n  @apply py-24 bg-white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Steps_root__O6Ny9`
};
export default ___CSS_LOADER_EXPORT___;
