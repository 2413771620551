import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import MerchantBusinessInfo from "../../components/MerchantProfile/BusinessInfo";

const MerchantBusinessInfoPage = () => {
  return (
    <MerchantLayout>
      <MerchantBusinessInfo />
    </MerchantLayout>
  ); 
};

export default MerchantBusinessInfoPage;
